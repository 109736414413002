var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"overviewPage"}},[_c('div',{staticClass:"container-fluid ml-0"},[_c('div',{staticClass:"col-xl-12 d-flex flex-wrap col-md-6 counter-widget pl-0"},_vm._l((_vm.columnsCpt),function(col){return _c('enyo-card',{key:col.field,staticClass:"ml-0 mr-3 card-primary"},[_c('div',{attrs:{"slot":""},slot:"default"},[_c('p',{staticClass:"card-category"},[_vm._v(_vm._s(col.label || _vm.startCase(col.field)))]),_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.getTotal(col.field)))])])])}),1),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-xs-12 col-md-12 text-right pr-0"},[_c('button',{staticClass:"btn btn-simple btn-info",on:{"click":_vm.getStocks}},[_vm._v("Refresh")])])]),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-xs-12 col-md-6 text-left p-1"},[_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.isVillesDataDisplayed ? 'active' : '',attrs:{"aria-current":"page"},on:{"click":_vm.loadVillesData}},[_vm._v("Villes")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ",class:!_vm.isVillesDataDisplayed ? 'active btn-info' : 'text-info',attrs:{"href":"#"},on:{"click":_vm.loadMarchandsData}},[_vm._v("Marchands")])])])])]),_c('hr'),(_vm.isRefreshing)?[_vm._m(0),_c('small',[_vm._v("Calcul des stats, merci de patienter quelques instants...")])]:_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-md-12 counter-widget"},[_c('enyo-ajax-table',{attrs:{"options":{
            headerStyle: _vm.isVillesDataDisplayed ? 'primary' : 'info',
          },"title":'Interface de suivi des stocks',"rows":_vm.stocks,"columnsDisplayed":18,"needsRefresh":_vm.tableNeedsRefresh,"columns":[
            {
              field: 'idMarchand',
              label: 'marchand',
              type: 'relation',
              hidden: _vm.isVillesDataDisplayed,
              relation: 'marchand',
              relationUrl: '/crud/marchand',
              relationKey: 'id',
              relationLabel: 'nom',
            },
            { field: 'nom', label: 'Nom', type: 'text' },
            {
              field: 'idDepartement',
              label: 'Departement',
              type: 'relation',
              relation: 'departement',
              relationUrl: '/crud/departement',
              relationKey: 'id',
              relationLabel: 'nom',
              hidden: !_vm.isVillesDataDisplayed
            },
            {
              field: 'idRegion',
              label: 'Region',
              type: 'relation',
              relation: 'region',
              relationUrl: '/crud/region_admin',
              relationKey: 'id',
              relationLabel: 'nom',
              hidden: !_vm.isVillesDataDisplayed
            },
            {
              field: 'idPays',
              label: 'pays',
              type: 'relation',
              relation: 'pays',
              relationUrl: '/crud/pays',
              relationKey: 'id',
              relationLabel: 'nom',
              hidden: !_vm.isVillesDataDisplayed
            },
            {
              field: 'idVille',
              label: 'ville',
              type: 'relation',
              relation: 'ville',
              relationUrl: '/crud/ville',
              relationKey: 'id',
              relationLabel: 'nom',
              hidden:true
            },
            {
              field: 'active',
              label: 'Afficher les Marchands actif uniquement ?',
              type: 'string',
              enum: ['Oui', 'Non'],
              hidden: true,
            },
            {
              field: 'type',
              label: 'Type de résidences',
              type: 'string',
              enum: ['normale', 'virtuelle'],
              hidden: true
            },

            { field: 'nbDeCampings', label: 'Nb de campings', type: 'number' },
            { field: 'nbDeCampingAvecDispos', label: 'Nb de campings avec dispos', type: 'number' },
            { field: 'nbHotels', label: 'Nb d\'hotels', type: 'number' },
            { field: 'nbHotelsAvecDispos', label: 'Nb d\'hotels avec dispos', type: 'number' },
            { field: 'nbDeResidences', label: 'Nb de résidences', type: 'number' },
            { field: 'nbDeResidencesAvecDispos', label: 'Nb de résidences avec dispos', type: 'number' },
            { field: 'nbTotalEts', label: 'Nb total d\'ets', type: 'number' },
            { field: 'nbEtsAvecDispo', label: 'Nb total d\'ets avec dispos', type: 'number' },
            { field: 'nbDeDispos', label: 'Nb total de dispos', type: 'number' } ],"perPage":"20","actions":{ noActions: false, filter: false, refresh: false, exportLocal: true },"customTableTopActions":[
            {
              type: 'filter',
              field: 'idVille',
              label: 'Ville',
              defaultOperator: '$in',
            },
            {
              type: 'filter',
              field: 'idDepartement',
              label: 'Departement',
            },
            {
              type: 'filter',
              field: 'idRegion',
              label: 'Région',
            },
            {
              type: 'filter',
              field: 'idPays',
              label: 'Pays',
            },
            {
              type: 'filter',
              field: 'idMarchand',
            },
            {
              type: 'filter',
              field: 'active',
              label: 'Afficher les stocks en front',
            },
            {
              type: 'filter',
              field: 'type',
              label: 'Type de résidence'
            }
          ]},on:{"update:needsRefresh":function($event){_vm.tableNeedsRefresh=$event},"update:needs-refresh":function($event){_vm.tableNeedsRefresh=$event},"filter":_vm.getStocks}})],1)])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress mb-2"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated bg-info",staticStyle:{"width":"75%"},attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100"}})])}]

export { render, staticRenderFns }