import models from './models';

export default {
  appName: process.env.VUE_APP_NAME || 'TOOCAMP FLUX',
  appKey: process.env.VUE_APP_KEY || 'toocamp_flux_dashboard',
  appLogo: process.env.VUE_APP_LOGO || 'https://www.toocamp.com/images/superdeal.png',
  titleLogo: '',
  env: 'development', // production / test
  defaultLocale: 'fr',
  /* eslint-disable */
  apiUrl: process.env.API_URL || '/api',
  buildDate: process.env.VUE_BUILDDATE || new Date(),
  version: '',
  defaultTitle: process.env.VUE_APP_TITLE || 'Toocamp FLUX',
  googleAuth: false,
  facebookAuth: false,
  primaryKey: 'id', // || '_id'
  displayOptions: {
    register: false,
    passwordReset: true,
  },
  automaticModels: models,
  roles: {
    USER: { inherits: [] }, // can access the app
    BO: { inherits: ["USER"] }, // can access the ADMIN
    SALES: { inherits: ["USER"] }, // can access the ADMIN
    PARTNER: { inherits: ["SALES"] }, // can see tech tools, access project
    TOOCAMP: { inherits: ["PARTNER", "EDITORIAL"] }, //
    ADMIN: { inherits: ["TOOCAMP"] },
    SUPERADMIN: { inherits: ["ADMIN"] }
  },
  imageUrl: process.env.API || 'https://cdn.flux.toocamp.com'
};
