<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <form @submit.prevent="search()">
        <div class="row m-0">
          <div class="col-sm-11 col-xs-12 text-left p-1">
            <VueSelect
              class="bg-white form-vue-select"
              placeholder="Entrez un id marchand"
              v-model="idMarchand"
              :options="marchandsList"
              label="label"
              :reduce="(item) => item.id"
              @input="search"
            />
          </div>
          <div class="col-sm-1 col-xs-12 text-left p-1">
            <button @click="search()" class="btn btn-icon btn-sm btn-success btn-block">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </form>
      <hr />
      <div class="row" v-if="idMarchand && noResults">
        <div class="col-xs-12 col-md-12 text-center text-danger">Pas de marchand avec cet id.</div>
      </div>

      <div class="row" v-if="marchand">
        <div class="col-xs-12 col-md-12 text-right">
          <button
            class="mr-1 btn btn-info"
            type="button"
            :disable="!marchand || !marchand.id"
            @click="$router.push('/app/marchand/' + marchand.id)"
          >
            <i class="fa fa-info"></i>
            Fiche marchand
          </button>

          <button
            class="mr-1 btn btn-info"
            type="button"
            :disable="!marchand || !marchand.id"
            @click="$router.push('/app/flux_marchand_conf?filters[idMarchand]=' + marchand.id)"
          >
            <i class="fa fa-info"></i>
            Conf marchand
          </button>

          <div class="btn-group">
            <button
              v-if="
                marchandConfs &&
                  marchandConfs.length &&
                  marchandConfs[0].active &&
                  marchandConfs[0].idMarchand == idMarchand
              "
              class="btn btn-primary"
              @click="startScoring()"
            >
              <i class="fa fa-play"></i> Lancer le scoring
            </button>
            <button
              v-if="
                marchandConfs &&
                  marchandConfs.length &&
                  marchandConfs[0].active &&
                  marchandConfs[0].idMarchand == idMarchand
              "
              class="btn btn-primary"
              @click="startImport()"
            >
              <i class="fa fa-play"></i> Lancer le sourcing
            </button>
            <div class="dropdown">
              <button
                class="ml-1 btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                arias-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-upload"></i>
                Importer
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  v-for="site in marchand.sites"
                  :key="site.id"
                  @click="sendToSingleSite({ id_marchand: marchand.id, ...site })"
                  class="dropdown-item"
                  >{{ site.nom }}</a
                >
                <a
                  key="tous"
                  v-if="marchand.sites.length"
                  @click="sendToAll({ id_marchand: marchand.id })"
                  class="dropdown-item"
                  >Tous</a
                >
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" @click="addSiteAssociation">
                  <i class="fa fa-plus"></i> Ajouter un site
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="marchand" />
      <div class="row" v-if="marchand">
        <div class="col-xl-3 col-md-6 counter-widget">
          <enyo-card v-if="marchand">
            <div slot>
              <p class="card-category">Nom</p>
              <h3 class="card-title">{{ marchand.nom }}</h3>
            </div>
          </enyo-card>
        </div>
        <div class="col-xl-2 col-md-6 counter-widget">
          <enyo-card v-if="marchandConfs && marchandConfs.length">
            <div slot>
              <p class="card-category">Site</p>
              <h3 class="card-title">{{ marchandConfs[0].site }}</h3>
            </div>
          </enyo-card>
        </div>
        <div class="col-xl-2 col-md-6 counter-widget">
          <enyo-card
            v-if="marchandConfs && marchandConfs.length"
            :class="isMarchandConfActive ? 'card-success' : 'card-danger'"
          >
            <div slot>
              <p class="card-category">Conf Active</p>
              <h3 class="card-title">{{ isMarchandConfActive ? 'oui' : 'non' }}</h3>
            </div>
          </enyo-card>
        </div>
        <div class="col-xl-2 col-md-6 counter-widget"
        v-for="forfait in forfaits" :key="forfait.id"
        >
          <enyo-card
            :class="{
              'card-success': isForfaitActive(forfait) === true,
              'card-danger': isForfaitActive(forfait) === false,
              'bg-default': isForfaitActive(forfait) === undefined,
            }"
          >
            <div slot>
              <p class="card-category">Forfait {{forfait.site && forfait.site.nom}}</p>
              <h3 class="card-title">{{ isForfaitActiveLabel(forfait) }}</h3>
            </div>
          </enyo-card>
        </div>
        <div class="col-xl-2 col-md-6 counter-widget" v-if="disposStats">
          <enyo-card v-if="disposStats.sitesTotal">
            <div slot>
              <p class="card-category">Sites</p>
              <h3 class="card-title">{{ formatNumber(disposStats.sitesTotal) }}</h3>
            </div>
          </enyo-card>
        </div>
        <div class="col-xl-2 col-md-6 counter-widget" v-if="disposStats">
          <enyo-card v-if="disposStats">
            <div slot>
              <p class="card-category">Produits en BC</p>
              <h3 class="card-title">{{ formatNumber(disposStats.produitsTotal) }}</h3>
            </div>
          </enyo-card>
        </div>

        <div class="col-xl-2 col-md-6 counter-widget" v-if="disposStats">
          <enyo-card class="card-teal" v-if="disposStats.disposByDates">
            <div slot>
              <p class="card-category">Dispos en BC</p>
              <h3 class="card-title">{{ formatNumber(disposStats.disposByDates.total) }}</h3>
            </div>
          </enyo-card>
        </div>

        <div class="col-xl-2 col-md-6 counter-widget" v-if="disposStats">
          <enyo-card class="card-teal" v-if="disposStats.totalResidencesWithDispos !== undefined">
            <div slot>
              <p class="card-category">Campings avec dispos en BC</p>
              <h3 class="card-title">{{ formatNumber(disposStats.totalResidencesWithDispos) }}</h3>
            </div>
          </enyo-card>
        </div>

        <template v-if="marchandFrontStats">
          <div class="col-xl-2 col-md-6 counter-widget" v-for="(stat, index) in marchandFrontStats" :key="index">
            <div class="card card-primary">
              <div class="card-body">
                <p class="card-category">Dispos dans Postgres ({{ stat.site.nom }})</p>
                <h3 class="card-title">{{ stat.disposTotal }}</h3>
              </div>
            </div>
          </div>
        </template>

        <template v-if="elasticsearchStats">
          <div class="col-xl-2 col-md-6 counter-widget" v-for="(stat, index) in elasticsearchStats" :key="index">
            <div class="card card-danger" v-if="disposStats">
              <div class="card-body">
                <p class="card-category">Dispos dans Elasticsearch ({{ stat.site.nom }})</p>
                <h3 class="card-title">{{ stat.displayedOnWebsite }}</h3>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="row" v-if="marchand">
        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-ajax-table
            :title="'Dispos par sites'"
            :columns="[
              { field: 'site' },
              { field: 'value', label: '#', type: 'number', formatFn: numberFormat },
              { field: 'ACTIONS', label: 'ACTIONS', type: 'string' },
            ]"
            url="/stats/dispo-by-merchant"
            :api-query-params="{ filters: { idMarchand }, options: { searchMode: 'exact' } }"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{
              headerStyle: 'info',
              perPage: 10,
              customInlineActions: [
                {
                  name: 'clear-monitoring-list',
                  label: '',
                  class: 'btn-success btn-simple',
                  title: 'Envoyer sur toocamp',
                  icon: 'fa fa-upload',
                },
              ],
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
            }"
            @customAction="actionCallback"
          />
        </div>
        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-table-and-charts-card
            v-if="disposByResidenceTypes"
            :title="'Dispos par type de résidences'"
            initialState="table"
            :table-columns="[{ field: 'type' }, { field: 'nombre', type: 'number', formatFn: numberFormat }]"
            :data-source="disposByResidenceTypes"
            :needs-refresh.sync="tableNeedsRefresh"
            :table-options="{ headerStyle: 'info', actions: { noActions: true, filter: false, refresh: true } }"
            :chart-options="{ headerStyle: 'info' }"
            :chart-col-key="'type'"
          />
        </div>

        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-ajax-table
            v-if="disposStats && disposStats.disposByResidenceFlag"
            :title="'Dispos par flag de résidences'"
            :columns="[{ field: 'flag' }, { field: 'nombre', type: 'number', formatFn: numberFormat }]"
            :rows="disposStats.disposByResidenceFlag"
            :perPage="10"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{ headerStyle: 'info', actions: { noActions: true, filter: false, refresh: true } }"
          />
        </div>

        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-ajax-table
            v-if="disposStats && disposStats.residencesWithDispos"
            :title="'Dispos par résidences'"
            :columns="[{ field: 'id' }, { field: 'nombre', type: 'number', formatFn: numberFormat }]"
            :rows="disposStats.residencesWithDispos"
            :perPage="10"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{ headerStyle: 'info', actions: { noActions: true, filter: false, refresh: true } }"
          />
        </div>

        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-ajax-table
            v-if="disposByDates && disposByDates.flux"
            :title="'Nb de dispos par date'"
            :columns="[
              { field: 'date_debut', type: 'date' },
              { field: 'value', label: '#', type: 'number', formatFn: numberFormat },
            ]"
            :rows="disposByDates.flux"
            :api-query-params="{ filters: { idMarchand: idMarchand }, options: { searchMode: 'exact' } }"
            :needs-refresh.sync="tableNeedsRefresh"
            :perPage="10"
            :options="{
              headerStyle: 'info',
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
            }"
          >
            <template v-slot:table-top-actions>
              <label>
                <input
                  name="filter-dispos-date"
                  @change="getDisposByDate()"
                  placeholder="Filtrer par camping id"
                  type="text"
                  class="vgt-input text-black-50"
                  v-model="disposByDateSearch.flux"
                />
              </label>
            </template>
          </enyo-ajax-table>
        </div>

        <div class="col-xl-4 col-md-6 counter-widget">
          <enyo-ajax-table
            :title="'Dernières dispos dans flux'"
            :columns="[
              { field: 'idMarchand', type: 'number' },
              { field: 'dateDebut', type: 'date' },
              { field: 'dateFin', type: 'date' },
              { field: 'duree', type: 'number' },
              { field: 'tarifNormal', type: 'number' },
              { field: 'tarifPromo', type: 'number' },
              { field: 'createdOn', type: 'datetime' },
            ]"
            url="/crud/disponibilite"
            :api-query-params="{ filters: { idMarchand }, options: { searchMode: 'exact' } }"
            :needs-refresh.sync="tableNeedsRefresh"
            :perPage="10"
            mode="remote"
            :options="{
              headerStyle: 'info',
              perPage: 10,
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
            }"
            @customAction="actionCallback"
          />
        </div>
        <div class="row">
          <template v-if="marchandFrontStats">
            <div v-if="marchandFrontStats && marchandFrontStats.length < 1" class="text-danger">
              Pas de statistiques récupérées en front.
            </div>
            <div class="col-xl-4 col-md-6 counter-widget" v-for="(marchand, index) in marchandFrontStats" :key="index">
              <enyo-ajax-table
                :title="'Dispos par date dans ' + (marchand.site && marchand.site.nom)"
                :columns="[{ field: 'date_debut', type: 'date' }, { field: 'c', label: '#', type: 'number' }]"
                :rows="marchand.disposByDate"
                :api-query-params="{ filters: { idMarchand: idMarchand }, options: { searchMode: 'exact' } }"
                :needs-refresh.sync="tableNeedsRefresh"
                :perPage="10"
                :options="{
                  headerStyle: 'primary',
                  actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
                }"
              >
                <template v-slot:table-top-actions>
                  <label>
                    <input
                      name="filter-dispos-date-front"
                      @change="getMarchandFrontStats()"
                      placeholder="Filtrer par camping id"
                      type="text"
                      class="vgt-input text-black-50"
                      v-model="disposByDateSearch.front"
                    />
                  </label>
                </template>
              </enyo-ajax-table>
            </div>
          </template>
        </div>
        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            :rows="elasticsearchStats"
            :title="'Statistiques des dispos dans Elasticsearch'"
            :columns="[
              { field: 'index' },
              { field: 'inDb', label: 'Nb total', type: 'number' },
              { field: 'validDate', label: 'Date de début valide', type: 'number' },
              { field: 'disabledForfait', label: 'Sans forfait', type: 'number' },
              { field: 'displayedOnWebsite', label: 'Visibles sur le site', type: 'number' },
            ]"
            :needs-refresh.sync="elasticTableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'danger',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'danger' }"
            :chart-col-key="'id_marchand'"
            @refresh="getEsStats"
          />
        </div>
        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/processus"
            :title="'Processus récents'"
            :columns="[
              { field: 'flux.id_marchand', type: 'number' },
              { field: 'pid', type: 'number' },
              { field: 'dateDeDebut', type: 'datetime' },
              { field: 'dateDeFin', type: 'datetime' },
              { field: 'statut', styleClasses: 'text-light' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :api-query-params="{ filters: { idMarchand: idMarchand }, options: { searchMode: 'exact' } }"
            :options="{
              headerStyle: 'success',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'info' }"
            :chart-col-key="'id_marchand'"
            @view="
              (item) => {
                $router.push('/app/processus/' + item.id);
              }
            "
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/crud/statistiques"
            :title="'Statistiques d\'import'"
            :columns="[
              { field: 'id', type: 'number' },
              { field: 'nbDispos', type: 'number' },
              { field: 'nbDisposAjoutees', type: 'number' },
              { field: 'dateImportDebut', type: 'datetime' },
              { field: 'dateImportFin', type: 'datetime' },
              { field: 'dureeExec', type: 'number' },
              { field: 'ACTIONS', label: 'ACTIONS', type: 'string' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            :api-query-params="{ filters: { idMarchand: idMarchand }, options: { searchMode: 'exact' } }"
            @view="openStatsPage"
            perPage="10"
            @customAction="openStatsPage"
            :options="{
              headerStyle: 'success',
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>
        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            :title="'Flux marchand Conf'"
            :columns="[{ field: 'field', type: 'string' }, { field: 'value' }]"
            :rows="marchandConfData"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{
              headerStyle: 'info',
              perPage: 10,
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
            }"
          />
        </div>
        <div class="col-xl-6 col-md-6 counter-widget" v-for="(forfaitData, index) in forfaitsData" :key="index">
          <enyo-ajax-table
            :title="'Forfait'"
            :columns="[{ field: 'field', type: 'string' }, { field: 'value' }]"
            :rows="forfaitData"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{
              headerStyle: 'primary',
              perPage: 10,
              actions: { noActions: true, filter: false, refresh: true, delete: false, edit: false, view: false },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin } from 'vue-aw-components';
import VueSelect from 'vue-select';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
// import qs from 'qs';

export default {
  name: 'Sherlock',
  mixins: [rolesMixin],
  components: { VueSelect },
  data() {
    return {
      generalStats: {},
      disposStats: {},
      disposByMerchant: [],
      disposByResidenceTypes: [],
      disposByDates: {
        flux: [],
      },
      idMarchand: null,
      marchand: null,
      marchandConfs: null,
      marchandFrontStats: [],
      elasticsearchStats: [],
      noResults: false,
      missingDailyStandupMembers: {
        date: null,
        columns: ['firstname', 'lastname', 'username'],
        data: [],
      },
      dateRange: {
        startDate: moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      disposByDateSearch: {
        front: '',
        flux: '',
      },
      lastRefreshedAt: null,
      graphNeedsRefresh: false,
      tableNeedsRefresh: false,
      elasticTableNeedsRefresh: false,
      currentMonth: moment().format('MM-YYYY'),
      currentYear: moment().format('YYYY'),
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },

      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 500,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      statsTable: {
        // columns: ['item', 'count', 'value'],
        columns: [
          {
            label: this.$t('app.projects.months'),
            field: 'item',
          },
          {
            label: this.$t('app.projects.contracts'),
            field: 'count',
          },
          {
            label: this.$t('app.projects.value'),
            field: 'valueInEuros',
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    this.searchDebounced = _.debounce(this.search);
    try {
      if (!this.$store.state.marchands || !this.$store.state.marchands.length) {
        this.$store.dispatch('getMarchands');
      }
      const idMarchand = this.$route.params.idMarchand || this.$route.query.idMarchand;
      if (idMarchand) {
        this.idMarchand = idMarchand;
        this.search();
        return;
      }
      const id = sessionStorage.getItem('flux_sherlock_current_marchand');
      if (id) {
        this.idMarchand = id;
        this.$router.push(`/app/sherlock/${this.idMarchand}`);
      }
      //  this.$nextTick(() => this.$refs.marchand.click() && this.$refs.marchand.$el.click());
    } catch (e) {
      console.warn(e);
    }
  },

  beforeDestroy() {},

  computed: {
    marchandsList() {
      return this.$store.state.marchands && this.$store.state.marchands
        ? this.$store.state.marchands
          .map((m) => ({ id: m.id, label: `${m.nom} (${m.id})` }))
          .sort((a, b) => a.id - b.id)
        : [];
    },
    lastRefreshedAtComputed() {
      return moment(this.lastRefreshedAt).format('dddd - DD MMM hh:mm:ss');
    },
    marchandConfData() {
      return (
        this.marchandConfs
        && this.marchandConfs[0]
        && Object.entries(this.marchandConfs[0]).map((e) => ({
          field: e[0],
          value: e[1],
        }))
      );
    },
    forfaits() {
      if (
        !(
          this.marchandFrontStats
          && this.marchandFrontStats[0]
        )
      ) {
        return [];
      }
      return this.marchandFrontStats.map(mfs => {
        const forfait = mfs.forfaits[0];
        if (!forfait) {
          return {
            site: mfs.site,
          };
        }
        forfait.site = mfs.site;
        return forfait;
      });
    },
    forfaitsData() {
      return this.forfaits && this.forfaits.map(forfait => Object.entries(forfait).map((e) => ({ field: e[0], value: e[1] })));
    },

    isMarchandConfActive() {
      return this.marchandConfs && this.marchandConfs.find((m) => m.active);
    },
  },
  methods: {
    search() {
      this.getMarchand();
      this.getMarchandConf();
      this.getStats();
      this.getEsStats();
      this.getMarchandFrontStats();
      this.getDisposByDate();
      this.getDisposByResidenceTypes();
      sessionStorage.setItem('flux_sherlock_current_marchand', this.idMarchand);
    },

    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return number;
      }
      return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    },

    getStats() {
      if (!this.idMarchand) {
        return;
      }
      this.disposStats = null;
      this.$http
        .get(`/stats/sherlock/overview?idMarchand=${this.idMarchand}`)
        .then((res) => {
          this.disposStats = res.data.body;
          this.tableNeedsRefresh = true;
        })
        .catch((err) => {
          console.warn(err);
        });
      this.lastRefreshedAt = new Date();
    },

    getEsStats() {
      if (!this.idMarchand) {
        return;
      }
      this.elasticsearchStats = null;
      this.$http
        .get(`/stats/dispos-in-elasticsearch?idMarchand=${this.idMarchand}`, {
          params: { filters: { idMarchand: this.idMarchand }, options: { searchMode: 'exact' } },
        })
        .then((res) => {
          this.elasticsearchStats = res.data.body;
          this.elasticTableNeedsRefresh = true;
        })
        .catch((err) => {
          console.warn(err);
        });
      this.lastRefreshedAt = new Date();
    },

    getDisposByDate() {
      if (!this.idMarchand) {
        return;
      }
      this.$http
        .get(`/stats/dispos-by-dates?idMarchand=${this.idMarchand}&idCamping=${this.disposByDateSearch.flux}`)
        .then((res) => {
          this.disposByDates.flux = res.data.body;
        })
        .catch((err) => {
          console.warn(err);
        });
    },

    getMarchand() {
      this.marchand = null;
      this.$http
        .get(`/marchand/${this.idMarchand}`)
        .then((res) => {
          if (!res || !res.data) {
            this.noResults = true;
          }
          this.marchand = res.data.body;
          this.noResults = false;
        })
        .catch((err) => {
          console.warn(err);
          this.noResults = true;
        });
    },

    getMarchandConf() {
      this.marchandConfs = null;
      this.$http
        .get(
          `/flux_marchand_conf?filters[idMarchand]=${this.idMarchand}&filters[active]=${1}&options[searchMode]=exact`,
        )
        .then((res) => {
          this.marchandConfs = res.data.body;
          if (this.marchandConfs.length > 1) {
            this.$notify({ message: 'Ce marchand à plusieurs configurations actives', type: 'warning' });
          }
        })
        .catch((err) => console.warn(err));
    },

    getMarchandFrontStats() {
      this.marchandFrontStats = null;
      this.$http
        .get(`/marchand/${this.idMarchand}/front-stats?idEtablissement=${this.disposByDateSearch.front}`)
        .then((res) => {
          this.marchandFrontStats = Array.isArray(res.data.body) ? res.data.body : [res.data.body];
        })
        .catch((err) => console.warn(err));
    },

    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },
    actionCallback(body) {
      const { item } = body;
      this.sendToToocamp(item);
    },

    sendToSingleSite(item) {
      // eslint-disable-next-line
      if (window.confirm('Êtes-vous de vouloir effectuer cette action ?')) {
        this.$http
          .post(`/marchand/${item.id_marchand}/send-to-toocamp`, {}, { params: { id_site: item.id } })
          .then(() => {
            this.$notify(`Marchand envoyé sur ${item.nom}`);
          })
          .catch(console.warn);
      }
    },

    sendToAll(item) {
      const sitesID = [];
      const sitesNames = [];
      Object.keys(this.marchand.Sites).forEach((key) => {
        sitesID.push(this.marchand.Sites[key].id);
        sitesNames.push(this.marchand.Sites[key].nom);
      });
      // eslint-disable-next-line
      if (window.confirm('Êtes-vous de vouloir effectuer cette action ?')) {
        this.$http
          .post(`/marchand/${item.id_marchand}/send-to-toocamp`, {}, { params: { id_sites: sitesID } })
          .then(() => {
            sitesNames.forEach((siteName) => {
              this.$notify(`Marchand envoyé sur ${siteName}`);
            });
          })
          .catch(console.warn);
      }
    },

    sendToToocamp(item) {
      // eslint-disable-next-line
      if (window.confirm('Êtes-vous de vouloir effectuer cette action')) {
        this.$http
          .post(`/marchand/${item.id_marchand}/send-to-toocamp`, {
            site: item.site,
          })
          .then(() => {
            this.$notify(`Marchand envoyé sur ${item.site}.toocamp.com`);
          })
          .catch(console.warn);
      }
    },

    startImport() {
      // eslint-disable-next-line
      if (this.marchand && this.marchand.id && window.confirm('Lancer le flux ? ')) {
        this.$http
          .post(`/processus/start-flux?idMarchand=${this.marchand.id}`, {})
          .then(() => this.$notify('Le sourcing du marchand à bien été lancé.'))
          .catch((err) => this.$notify({
            title: `Le sourcing n'a pas pu être lancé: ${err}`,
            type: 'danger',
          }));
      }
    },

    startScoring() {
      if (this.marchand && this.marchand.id && window.confirm('Lancer le scoring ? ')) {
        this.$http
          .post(`/processus/create-scoring-job?idMarchand=${this.marchand.id}`, {})
          .then(() => this.$notify('Le scoring du marchand à bien été lancé.'))
          .catch((err) => this.$notify({
            title: `Le scoring n'a pas pu être lancé: ${err}`,
            type: 'danger',
          }));
      }
    },

    openStatsPage(item) {
      this.$router.push(`/app/statistiques?filter[filters][idMarchand]=${item.idMarchand}`);
    },

    getDisposByResidenceTypes() {
      if (!this.idMarchand) {
        return;
      }
      this.$http
        .get(`/stats/dispos-by-residence-types?idMarchand=${this.idMarchand}`)
        .then((res) => {
          this.disposByResidenceTypes = res.data.body;
        })
        .catch((err) => {
          console.warn(err);
        });
    },

    addSiteAssociation() {
      this.$router.push({
        path: `/app/site_marchand/new?item[idMarchand]=${this.idMarchand}`,
      });
    },

    numberFormat(data) {
      return new Intl.NumberFormat('de-DE').format(data);
    },

    isForfaitActive(forfait) {
      if (forfait === undefined) {
        return;
      }
      const now = new Date();
      return (
        forfait
        && forfait.active
        && new Date(forfait.date_debut_validite) < now
        && new Date(forfait.date_fin_validite) > now
        && forfait.clics_restant > 0
      );
    },

    isForfaitActiveLabel(forfait) {
      const value = this.isForfaitActive(forfait);
      if (value === undefined) {
        return '-';
      }
      return value ? 'Actif' : 'Inactif';
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.form-vue-select {
  height: 100%;
  .vs__dropdown-toggle {
    height: 100%;
  }
}
</style>
