<template>
  <div class="content editroialContainer">
    <div class="card">
      <div class="card-body">
        <div class="row row-container" v-if="titleEdit === false">
          <div class="col-12 sectionContainer">
            <h1>{{ residence.nom }}</h1>
            <button class="btn btn-primary btn-simple p-1 set-button-right" @click="handleModifications">
              {{ !editMode ? 'Modifier' : 'Valider' }}
            </button>
            <button class="btn btn-primary btn-simple p-1 close-button" @click="() => $router.push('/app/residence')">
              X
            </button>
          </div>
        </div>
        <div v-else>
          <div class="col-12 sectionContainer">
            <input type="text" class="form-control" v-model="residence.nom" />
            <button class="btn btn-primary btn-simple p-1 pull-right" @click="titleEdit = false">valider</button>
          </div>
        </div>
        <div class="row" v-if="titleEdit === false">
          <div class="col-12 sectionContainer">
            <awesome-form
              identity="residence-editorial"
              :item="residence"
              :mode="editMode ? 'edit' : 'view'"
              displayMode="page"
              :displayHeader="false"
              :actions="{ edit: false, delete: false }"
              :customInlineActions="[{ showCancelButton: true }]"
              :editLayoutMode="true"
            ></awesome-form>
          </div>
        </div>
        <div>
          <div class="sectionContainer">
            <h3 class="section">Descriptions</h3>
          </div>
          <div class="row descriptionContainer">
            <div class="col-12">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li
                  class="nav-item"
                  role="presentation"
                  v-for="lang in locales"
                  :key="lang"
                  @click="onChangeLanguage(lang)"
                >
                  <a
                    class="nav-link"
                    :class="{ active: lang === selectedLang }"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    {{ lang }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active">
                  <div class="card" v-if="!editMode">
                    <div class="card-body">
                      <template v-if="selectedResidenceLang">
                        <h5>
                          {{ selectedResidenceLang.nom }}
                        </h5>
                        <hr />
                        <div aria-labelledby="nav-home-tab" v-html="selectedResidenceLang.description"></div>
                      </template>
                      <template v-else>
                        <button class="btn btn-primary" @click="addLanguageClicked">
                          Ajouter la traduction {{ selectedLang }}
                        </button>
                      </template>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <h3>Titre</h3>
                      <input
                        type="text"
                        class="form-control languageInput"
                        v-model="selectedResidenceLang.nom"
                        :placeholder="`Entrez le nom en ${selectedLang}`"
                      />
                      <div>
                        <h3>Description</h3>
                        <wysiwyg v-model="selectedResidenceLang.description"></wysiwyg>
                      </div>
                      <div>
                        <h3>Description courte toocamp</h3>
                        <wysiwyg v-model="selectedResidenceLang.descriptionCourteInterne"></wysiwyg>
                      </div>
                      <div>
                        <h3>Description courte pour les affiliés <a href="affiliés"></a></h3>
                        <wysiwyg v-model="selectedResidenceLang.descriptionCourteAffilies"></wysiwyg>
                      </div>
                      <div>
                        <h3>Description longue toocamp</h3>
                        <wysiwyg v-model="selectedResidenceLang.descriptionLongueInterne"></wysiwyg>
                      </div>
                      <div>
                        <h3>Description longue pour les affiliés <a href="affiliés"></a></h3>
                        <wysiwyg v-model="selectedResidenceLang.descriptionLongueAffilies"></wysiwyg>
                      </div>
                      <button class="btn btn-primary ml-2 pull-right mt-3" @click="saveNewLanguage">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div class="sectionContainer">
          <h3 class="section">Images</h3>
        </div>

        <div class="row">
          <div class="col-xl-3 col-md-3 col-xs-12 imageContainer" v-for="item in residence.photos" :key="item.id">
            <div class="iconContainer" @click="deleteImage(item.id)">
              <i class="fa fa-trash fa-lg trashIcon" aria-hidden="true"></i>
            </div>
            <img
              class="image"
              :src="item.src ? `${item.src.indexOf('http') === -1 ? `${cdnHost}/` : ''}${item.src}` : ''"
              width="100%"
              @click="openPictureModal(item.id)"
            />
          </div>
          <div class="col-xl-3 col-md-3 col-xs-12 imageContainer">
            <div
              style="
                margin: auto;
                text-align: center;
                vertical-align: middle;
                justify-content: center;
                align-items: center;
                height: 100%;
                display: flex;
              "
              class="image"
            >
              <upload-button
                @uploaded="onUpload($event)"
                v-bind:options="{
                  label: 'Ajouter une photo',
                  targetUrl: `/residence/${this.$route.params.id}/upload-image`,
                  upload: true,
                  method: 'post',
                }"
                v-bind:name="file"
              />
            </div>
          </div>
        </div>
        <hr />
        <hr />
        <div class="row" v-if="isPictureEditable">
          <div class="col-12 sectionContainer">
            <awesome-form
              identity="residence-picture"
              :item="pictureIdObj"
              mode="edit"
              displayMode="modal"
              :standalone="true"
              :displayHeader="true"
              @cancel="cancelPictureEdit"
              @closeRequested="cancelPictureEdit"
              :actions="{ edit: true, delete: false, cancel: true, close: true }"
              :awFormDisplayHeader="true"
            >
            </awesome-form>
          </div>
        </div>
        <div class="savebutton">
          <button class="btn btn-primary" @click="saveEdit">Sauvegarder</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config';

export default {
  name: 'EditorialPage',
  data() {
    return {
      titleEdit: false,
      residence: {},
      selectedLang: '',
      selectedResidenceLang: {},
      editMode: false,
      addLanguageSelected: false,
      language: '',
      newLanguageNom: '',
      cdnHost: config.imageUrl,
      locales: ['frfr', 'dede', 'itit', 'eses', 'enuk', 'nlnl'],
      file: null,
      isPictureEditable: false,
      columns: [{ field: 'id', label: 'id', type: 'number' }, { field: 'saison', label: 'Saison', type: 'string' }],
      pictureIdObj: {},
    };
  },
  methods: {
    async openPictureModal(pictureId) {
      this.pictureIdObj = { id: pictureId };
      this.isPictureEditable = true;
    },
    formatDate(date) {
      if (!date) {
        return;
      }
      return date.replace('T', ' ').replace('.000Z', '');
    },
    handleModifications() {
      this.editMode = !this.editMode;
      this.selectedResidenceLang = this.residence.residence_lang.find((rl) => rl.lang === this.selectedLang);
    },
    getResidenceInfo() {
      this.$http
        .get(`/residence/${this.$route.params.id}`)
        .then((res) => {
          if (res.status === 200) {
            this.residence = res.data.body;
            if (this.residence.residence_lang && this.residence.residence_lang[0]) {
              this.onChangeLanguage(this.residence.residence_lang[0].lang);
              this.selectedResidenceLang = this.residence.residence_lang.find((rl) => rl.lang === this.selectedLang);
            }
          }
        })
        .catch((error) => {
          console.log('error', error);
          this.$router.push('/app/404');
        });
    },
    saveEdit() {
      this.isPictureEditable = false;
      this.$http
        .put(`/residence/${this.$route.params.id}/editorial`, {
          name: this.residence.nom,
          descriptions: this.residence.residence_lang,
        })
        .then((res) => {
          if (res.status === 200) {
            return;
          }
        })
        .catch((err) => console.error(err));
    },
    cancelPictureEdit() {
      this.isPictureEditable = false;
    },
    saveNewLanguage() {
      if (this.selectedLang) {
        const newItem = {
          id: this.selectedResidenceLang.id,
          lang: this.selectedLang,
          nom: this.newLanguageNom,
          ...this.selectedResidenceLang,
        };
        this.selectedResidenceLang = newItem;
        this.residence.residence_lang = this.residence.residence_lang.filter((rl) => rl.lang !== this.selectedLang);
        this.residence.residence_lang.push(newItem);
        this.editMode = false;
        this.addLanguageSelected = false;

        this.language = '';
      }
    },

    onChangeLanguage(i) {
      this.selectedLang = i;
      this.addLanguageSelected = false;
      this.editMode = false;
      this.newLanguageNom = '';
      this.selectedResidenceLang =
        this.residence &&
        this.residence.residence_lang &&
        this.residence.residence_lang.find((r) => r.lang === this.selectedLang);
    },

    addLanguageClicked() {
      this.editMode = true;
      this.newLanguageNom = this.residence.nom;
      this.selectedResidenceLang = {
        nom: this.residence.nom,
      };
    },

    onUpload(image) {
      this.residence.photos.push({
        src: image.upload.src,
        id: image.upload.id,
      });
    },
    deleteImage(id) {
      this.$http.delete(`/residence/${id}/delete-image`).then((res) => {
        this.residence.photos = this.residence.photos.filter((el) => el.id !== id);
      });
    },
  },
  mounted() {
    this.getResidenceInfo();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.buttonsContainer {
  display: flex;
  margin-top: 20px;
}
.sectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonLanguage {
  height: 40px;
  width: 100px;
  padding: 6px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: none;
  cursor: pointer;
  background: #f0f0f0;
  margin-bottom: -1px;
  margin-right: -1px;
  overflow-anchor: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeButtonLanguage {
  background: #e0e0e0;
}
.section {
  margin-top: 20px;
}
.descriptionContainer {
  padding-left: 30px;
}
.imageContainer {
  height: 200px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 25px;
  position: relative;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.image {
  border-radius: 25px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.savebutton {
  display: flex;
  justify-content: flex-end;
}
.languageInput {
  margin: 15px 0;
}
.trashIcon {
  color: #ff9900;
}
.trashIcon {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
}
.editroialContainer {
  min-height: 100vh;
}
.descriptionContainer {
  min-height: 200px;
}

.set-button-right {
  right: 50px;
  position: absolute;
}

.close-button {
  right: 0px;
  position: absolute;
}

.row-container {
  width: 100%;
}
</style>
