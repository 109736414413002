<template>
  <transition name="fade" mode="out-in">
    <router-view :key="key"></router-view>
  </transition>
</template>
<script>
export default {
  mounted() {
    this.$awEventBus.$on('aw-form-open', () => {
      const content = document.querySelector('.main-panel');
      content.scroll(0, 0);
    });
  },
  beforeDestroy() {
    this.$awEventBus.$off('aw-form-open');
  },

  watch: {
  },
  computed: {
    key() {
      return this.$route.meta.forceRefresh
        ? Date.now()
        : this.$route.meta.identity || this.$route.name || this.$route.path;
    },
  },
};
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
