import $ from 'jquery';

// vue and vue components
import Vue from 'vue';
import Vuex from 'vuex';
import Multiselect from 'vue-multiselect';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import CripVueLoading from 'crip-vue-loading';
import wysiwyg from 'vue-wysiwyg';

// import VueLoading from 'vue-loading-overlay';
import { FormGenerator, notificationsMixin } from 'vue-aw-components';

import VueGoodTablePlugin from 'vue-good-table';

import VueEnyoComponents from 'vue-aw-components/src/plugin';
import en from 'vue-aw-components/src/translations/en.json';
import fr from 'vue-aw-components/src/translations/fr.json';
// External libraries
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import _ from 'lodash';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';

import 'chartist-plugin-pointlabels';

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// / import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';

// router setup and local elements setup
import config from './config';
import store from './stores';
import routes from './routes/routes';
import * as filters from './filters/filters';
import App from './App.vue';

// plugin setup
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(LightBootstrap);
// Vue.use(VueLoading);
Vue.use(VueI18n);

Vue.use(FormGenerator, {
  fields: _.values(FormGenerator.fieldsLoader),
});

Vue.use(VueGoodTablePlugin);

Vue.use(require('vue-chartist'));

Vue.use(wysiwyg, {
  hideModules: {
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    link: true,
    code: true,
    orderedList: true,
    unorderedList: true,
    image: true,
    table: true,
    removeFormat: true,
    separator: true,
  },
});

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// Vue.use(Notifications)

Vue.component('multiselect', Multiselect);
Vue.component('vue-form-generator', FormGenerator, []);

// Vue.component('v-select', vSelect);
//
// Vue.use(Notify, {type: 'primary', timeout: 2000})

// global variables
window.App = {
  locale: config.defaultLocale || 'fr',
};
window.$ = $;
window.jQuery = $;
window._ = _;
window.moment = moment;
let vInstance;

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

Vue.prototype.$http = axios.create({
  baseURL: config.apiUrl,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(`${config.appName}_token`)}`,
  },
});

Vue.use(CripVueLoading, { axios: Vue.prototype.$http });

Vue.prototype.$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      /*
        swal({
          title: "Session Expired",
          text: "Your session has expired. Would you like to be redirected to the login page?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          closeOnConfirm: false
        }, function(){
          window.location = '/login';
        });
        */

      if (
        error.response
        && error.response.config
        && error.response.config.url
        && (error.response.config.url.indexOf('/login') === -1
          || error.response.config.url.indexOf('/register') === -1
          || error.response.config.url.indexOf('/models') === -1)
      ) {
        console.warn('401 => Logging out the USER');
        store.dispatch('logout');
        if (vInstance.$router) {
          vInstance.$router.push('/login');
        }
        // v.$notify("Your session has expired");
      }
    }
    return Promise.reject(error);
  },
);

Vue.mixin(notificationsMixin);

Vue.use(VueEnyoComponents, {
  CrudComponent: { props: { primaryKey: { type: String, default: 'id' } } },
  EnyoAjaxTable: { props: { primaryKey: { type: String, default: 'id' } } },
});

if (!Vue.prototype.$notify && notificationsMixin.methods.$awNotify) {
  Vue.prototype.$notify = notificationsMixin.methods.$awNotify;
}
// configure router
const router = new VueRouter({
  base: '/',
  mode: window.cordova ? 'hash' : 'history',
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  parseQuery(query) {
    if (!query) {
      return {};
    }
    return qs.parse(query);
  },
  stringifyQuery(query) {
    if (!query || !Object.keys(query).length) {
      return '';
    }
    const result = qs.stringify(query);
    return result ? `?${result}` : '';
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${config.defaultTitle} - ${to.meta.title || to.name}`;
  next();
});
const languages = {
  fr,
  en,
};
const i18n = new VueI18n({
  locale: store.state.locale, // set locale
  messages: _.merge(languages, store.state.translations || {}), // set locale messages
  fallbackLocale: ['fr', 'en'],
  silentTranslationWarn: process.env.VUE_APP_NO_I18N_LOGS || process.env.NODE_ENV === 'production',
});

/* eslint-disable no-new */

store.commit('extendedRoles');
// initVue();

const io = sailsIOClient(socketIOClient);

io.sails.url = config.apiUrl.replace('/api', '');
io.sails.transports = ['websocket', 'htmlfile', 'xhr-polling', 'jsonp-polling', 'polling'];

io.sails.headers = {
  authorization: `Bearer ${store.state.token}`,
};

io.socket.get(
  '/api/users',
  {
    token: store.state.token,
    access_token: store.state.token,
  },
  // eslint-disable-next-line
  (body, JWR) => {
    // body === JWR.body
  },
);

store.commit('io', io);

function initVue() {
  vInstance = new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
    store,
    i18n,
  });
  console.log('process.env.NODE_ENV', process.env.NODE_ENV);
  if (process.env.NODE_ENV.toLowerCase() === 'development') {
    window.App.vue = vInstance;
  }

  // REFRESH DATA
  if (localStorage.getItem(`${config.appName}_token`)) {
    vInstance.$store.dispatch('getMarchands');
    vInstance.$store
      .dispatch('refreshUser')
      .then((res) => {
        vInstance.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
        if (
          vInstance.$route.name === 'Login'
          && vInstance.$store.state.user
          && vInstance.$store.state.user[config.primaryKey]
        ) {
          setTimeout(() => {
            vInstance.$router.push('/');
          }, 300);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

store
  .dispatch('getModels')
  .then(initVue)
  .catch(initVue);
