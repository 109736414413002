<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid ml-0">
      <div class="col-xl-12 d-flex flex-wrap col-md-6 counter-widget pl-0">
        <enyo-card v-for="col in columnsCpt" :key="col.field" class="ml-0 mr-3 card-primary">
          <div slot>
            <p class="card-category">{{ col.label || startCase(col.field) }}</p>
            <h3 class="card-title">{{ getTotal(col.field) }}</h3>
          </div>
        </enyo-card>
      </div>

      <div class="row m-0">
        <div class="col-xs-12 col-md-12 text-right pr-0">
            <button class="btn btn-simple btn-info" @click="getStocks">Refresh</button>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-xs-12 col-md-6 text-left p-1">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link" aria-current="page"
              :class="isVillesDataDisplayed ? 'active' : ''"
              @click="loadVillesData"

              >Villes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="#"
              :class="!isVillesDataDisplayed ? 'active btn-info' : 'text-info'"
              @click="loadMarchandsData"
              >Marchands</a>
            </li>
          </ul>
        </div>
      </div>

      <hr />
      <template v-if="isRefreshing" >
        <div class="progress mb-2">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
        </div>
        <small>Calcul des stats, merci de patienter quelques instants...</small>
      </template>
      <div class="row">
        <div class="col-xl-12 col-md-12 counter-widget">
          <enyo-ajax-table
            :options="{
              headerStyle: isVillesDataDisplayed ? 'primary' : 'info',
            }"
            :title="'Interface de suivi des stocks'"
            :rows="stocks"
            :columnsDisplayed="18"
            :needsRefresh.sync="tableNeedsRefresh"

            :columns="[
              {
                field: 'idMarchand',
                label: 'marchand',
                type: 'relation',
                hidden: isVillesDataDisplayed,
                relation: 'marchand',
                relationUrl: '/crud/marchand',
                relationKey: 'id',
                relationLabel: 'nom',
              },
              { field: 'nom', label: 'Nom', type: 'text' },
              {
                field: 'idDepartement',
                label: 'Departement',
                type: 'relation',
                relation: 'departement',
                relationUrl: '/crud/departement',
                relationKey: 'id',
                relationLabel: 'nom',
                hidden: !isVillesDataDisplayed
              },
              {
                field: 'idRegion',
                label: 'Region',
                type: 'relation',
                relation: 'region',
                relationUrl: '/crud/region_admin',
                relationKey: 'id',
                relationLabel: 'nom',
                hidden: !isVillesDataDisplayed
              },
              {
                field: 'idPays',
                label: 'pays',
                type: 'relation',
                relation: 'pays',
                relationUrl: '/crud/pays',
                relationKey: 'id',
                relationLabel: 'nom',
                hidden: !isVillesDataDisplayed
              },
              {
                field: 'idVille',
                label: 'ville',
                type: 'relation',
                relation: 'ville',
                relationUrl: '/crud/ville',
                relationKey: 'id',
                relationLabel: 'nom',
                hidden:true
              },
              {
                field: 'active',
                label: 'Afficher les Marchands actif uniquement ?',
                type: 'string',
                enum: ['Oui', 'Non'],
                hidden: true,
              },
              {
                field: 'type',
                label: 'Type de résidences',
                type: 'string',
                enum: ['normale', 'virtuelle'],
                hidden: true
              },

              { field: 'nbDeCampings', label: 'Nb de campings', type: 'number' },
              { field: 'nbDeCampingAvecDispos', label: 'Nb de campings avec dispos', type: 'number' },
              { field: 'nbHotels', label: 'Nb d\'hotels', type: 'number' },
              { field: 'nbHotelsAvecDispos', label: 'Nb d\'hotels avec dispos', type: 'number' },
              { field: 'nbDeResidences', label: 'Nb de résidences', type: 'number' },
              { field: 'nbDeResidencesAvecDispos', label: 'Nb de résidences avec dispos', type: 'number' },
              { field: 'nbTotalEts', label: 'Nb total d\'ets', type: 'number' },
              { field: 'nbEtsAvecDispo', label: 'Nb total d\'ets avec dispos', type: 'number' },
              { field: 'nbDeDispos', label: 'Nb total de dispos', type: 'number' },
            ]"
            perPage="20"
            :actions="{ noActions: false, filter: false, refresh: false, exportLocal: true }"
            :customTableTopActions="[
              {
                type: 'filter',
                field: 'idVille',
                label: 'Ville',
                defaultOperator: '$in',
              },
              {
                type: 'filter',
                field: 'idDepartement',
                label: 'Departement',
              },
              {
                type: 'filter',
                field: 'idRegion',
                label: 'Région',
              },
              {
                type: 'filter',
                field: 'idPays',
                label: 'Pays',
              },
              {
                type: 'filter',
                field: 'idMarchand',
              },
              {
                type: 'filter',
                field: 'active',
                label: 'Afficher les stocks en front',
              },
              {
                type: 'filter',
                field: 'type',
                label: 'Type de résidence'
              }
            ]"

            @filter="getStocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin, apiErrorsMixin } from 'vue-aw-components';
import qs from 'qs';
import _ from 'lodash';

export default {
  mixins: [rolesMixin, apiErrorsMixin],
  data() {
    return {
      isVillesDataDisplayed: true,
      tableNeedsRefresh: false,
      isRefreshing: false,
      stocks: [],
      stats: {},
      columns: [
        { field: 'nbDeCampings', label: 'Nb de campings', type: 'number' },
        { field: 'nbDeCampingAvecDispos', label: 'Nb de campings avec dispos', type: 'number' },
        { field: 'nbHotels', label: 'Nb d\'hotels', type: 'number' },
        { field: 'nbHotelsAvecDispos', label: 'Nb d\'hotels avec dispos', type: 'number' },
        { field: 'nbDeResidences', label: 'Nb de résidences', type: 'number' },
        { field: 'nbDeResidencesAvecDispos', label: 'Nb de résidences avec dispos', type: 'number' },
        { field: 'nbTotalEts', label: 'Nb total d\'etablissements', type: 'number' },
        { field: 'nbEtsAvecDispo', label: 'Nb total d\'etablissements avec dispos', type: 'number' },
        { field: 'nbDeDispos', label: 'Nb total de dispos', type: 'number' },
      ],
      filters: {},
    };
  },
  mounted() {
    this.getStocks();
  },
  computed: {
    lastRefreshedAtComputed() {
      return moment(this.lastRefreshedAt).format('dddd - DD MMM hh:mm:ss');
    },

    columnsCpt() {
      return this.filters && this.filters.idMarchand ? this.columns.filter(c => !['nbDeCampings', 'nbDeResidences', 'nbHotels'].includes(c.field)) : this.columns;
    },
  },
  methods: {
    loadVillesData() {
      this.stocks = [];
      this.isVillesDataDisplayed = true;
      this.tableNeedsRefresh = true;
      this.getStocks();
    },

    loadMarchandsData() {
      this.stocks = [];
      this.isVillesDataDisplayed = false;
      this.tableNeedsRefresh = true;
      this.getStocks();
    },

    getTotal(field) {
      let total = 0;
      if (this.stats && Object.keys(this.stats).length > 0) {
        total = this.stats[field];
        return total;
      }
      if (this.stocks.length) {
        for (let i = 0; i < this.stocks.length; i++) {
          total += this.stocks[i][field];
        }
        return total;
      }
    },
    startCase(string) {
      return _.startCase(string);
    },
    getStocks(options = {}) {
      const { filters } = options;
      let urlStock;
      if (filters && Object.keys(filters).length > 0) {
        this.filters = filters;
      }
      this.isRefreshing = true;
      if (this.isVillesDataDisplayed) {
        urlStock = `/stats/stocks?${qs.stringify({ filters: this.filters })}`;
      } else {
        urlStock = `/stats/stocks-for-marchand?${qs.stringify({ filters: this.filters })}`;
      }
      this.$http
        .get(urlStock, { timeout: 30000 })
        .then((res) => {
          this.stocks = res.data.body;
          if (res.data.stats) {
            this.stats = res.data.stats;
          }
          this.tableNeedsRefresh = true;
        })
        .catch(this.apiErrorCallback)
        .finally(() => {
          this.isRefreshing = false;
        });

      this.lastRefreshedAt = new Date();
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.vs__dropdown-toggle .vs__selected-options > input {
  color: black !important;
}
.selected {
  width: 100px;
  height: 60px;
}
</style>
