<template>
  <div class="main-wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/app/overview/home">
        <i class="fa fa-table"></i>
        <div>{{ $t('common.labels.dashboard') }}</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('TOOCAMP')" to="/app/sherlock">
        <i class="fa fa-search text-success"></i>
        <div>Sherlock</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('TOOCAMP')" to="/app/debugger">
        <i class="fa fa-dashboard text-success"></i>
        <div>Debugger</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('TOOCAMP')" to="/app/monitoring">
        <i class="fa fa-dashboard text-success"></i>
        <div>Suivi des cron</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('TOOCAMP')" to="/app/matching/stats">
        <i class="fa fa-dashboard text-success"></i>
        <div>Stats de matching</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('TOOCAMP')" to="/app/overview/marchands">
        <i class="fa fa-table"></i>
        <div>Stats marchands</div>
      </sidebar-link>
      <sidebar-link v-if="userHasRole('SALES')" to="/app/overview/stocks">
        <i class="fa fa-table"></i>
        <div>Stats stocks</div>
      </sidebar-link>
      <div>
        <template v-if="userHasRole('TOOCAMP')">
          <hr />

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#sites">
            <i class="fa fa-caret-right"></i>
            Flux d'imports
          </h5>
          <div id="sites" class="">
            <sidebar-link to="/app/flux_marchand_conf">
              <i class="fa fa-list-alt text-primary"></i>
              <div
                class="text-truncate"
                data-tooltip="Correspondances des types d'hébergements"
                data-tooltip-position="bottom"
              >
                Flux d'importations
              </div>
            </sidebar-link>
            <sidebar-link to="/app/processus" class="text-success">
              <i class="fa fa-sitemap text-success"></i>
              <div class="text-truncate">Processus d'import</div>
            </sidebar-link>
            <sidebar-link to="/app/statistiques">
              <i class="fa fa-chart-area"></i>
              <div class="text-truncate">Statistiques d'import</div>
            </sidebar-link>
            <sidebar-link to="/app/monitoring">
              <i class="fa fa-stethoscope text-warning"></i>
              <div class="text-truncate">Monitoring des erreurs flux</div>
            </sidebar-link>
          </div>
          <hr />

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#ameliorations-menu">
            <i class="fa fa-caret-right"></i>
            Associations des données
          </h5>
          <div id="ameliorations-menu" class="collapse show">
            <sidebar-link to="/app/residence_association">
              <i class="fa fa-exchange text-success"></i>
              <div class="text-truncate">Associations des <br />Résidences</div>
            </sidebar-link>

            <sidebar-link to="/app/residence_association_manuelle">
              <i class="fa fa-exchange text-danger"></i>
              <div class="text-truncate">Associations des <br />Résidences indépendantes</div>
            </sidebar-link>

            <sidebar-link to="/app/ville_association">
              <i class="fa fa-caret-right"></i>
              <div class="text-truncate">Associations des <br />Villes</div>
            </sidebar-link>

            <sidebar-link to="/app/correspondance">
              <i class="fa fa-exchange"></i>
              <div
                class="text-truncate"
                data-tooltip="Correspondances des types d'hébergement"
                data-tooltip-position="bottom"
              >
                Associations des <br />types d'hébergement
              </div>
            </sidebar-link>
            <sidebar-link to="/app/association_pensions">
              <i class="fa fa-exchange"></i>
              <div class="text-truncate">Associations des <br />Pensions</div>
            </sidebar-link>
          </div>
          <hr />

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#global-menu">
            <i class="fa fa-caret-right"></i>
            Données globales
          </h5>
          <div id="global-menu" class="show collapse">
            <sidebar-link to="/app/marchand">
              <i class="fa fa-university"></i>
              <div class="text-truncate">Marchands</div>
            </sidebar-link>
            <sidebar-link to="/app/produit">
              <i class="fa fa-database"></i>
              <div class="text-truncate">Produits</div>
            </sidebar-link>
            <sidebar-link to="/app/disponibilite">
              <i class="fa fa-shopping-basket"></i>
              <div class="text-truncate">Dispos</div>
            </sidebar-link>
            <sidebar-link :to="{ path: '/app/residence?ts=' + Date.now(), meta: { forceRefresh: true } }">
              <i class="fa fa-hotel"></i>
              <div class="text-truncate">Résidences</div>
            </sidebar-link>
            <sidebar-link to="/app/hebergement_type">
              <i class="fa fa-home"></i>
              <div class="text-truncate">Types d'hébergements</div>
            </sidebar-link>
            <sidebar-link to="/app/residence_type">
              <i class="fa fa-home"></i>
              <div class="text-truncate">Type de résidence</div>
            </sidebar-link>
            <sidebar-link to="/app/caracteristique">
              <i class="fa fa-puzzle-piece"></i>
              <div class="text-truncate">Caractéristiques</div>
            </sidebar-link>
            <sidebar-link to="/app/pension">
              <i class="fa fa-calendar-check-o"></i>
              <div class="text-truncate">Pensions</div>
            </sidebar-link>
            <sidebar-link to="/app/option">
              <i class="fa fa-star"></i>
              <div class="text-truncate">Options</div>
            </sidebar-link>

            <sidebar-link to="/app/option_type">
              <i class="fa fa-star-o"></i>
              <div class="text-truncate">Options Type</div>
            </sidebar-link>
          </div>

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#geo">
            <i class="fa fa-caret-right"></i>
            Données géographiques
          </h5>
          <div id="geo">
            <sidebar-link to="/app/ville">
              <i class="fa fa-map-signs"></i>
              <div class="text-truncate">Villes</div>
            </sidebar-link>
            <sidebar-link to="/app/region_admin">
              <i class="fa fa-map-marker"></i>
              <div class="text-truncate">Régions administratives</div>
            </sidebar-link>
            <sidebar-link to="/app/pays">
              <i class="fa fa-map"></i>
              <div class="text-truncate">Pays</div>
            </sidebar-link>
          </div>

          <hr />

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#sites">
            <i class="fa fa-caret-right"></i>
            Gestion des sites
          </h5>
          <div id="sites" class="collapse">
            <sidebar-link to="/app/site">
              <i class="fa fa-sitemap"></i>
              <div class="text-truncate">Sites</div>
            </sidebar-link>
            <sidebar-link to="/app/site_marchand">
              <i class="fa fa-exchange"></i>
              <div class="text-truncate">Associations des marchands aux sites</div>
            </sidebar-link>
          </div>

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#mapping">
            <i class="fa fa-caret-right"></i>
            Mappings des données des sites
          </h5>
          <div id="mapping" class="collapse collapsed">
            <sidebar-link to="/app/mapping_site_residence">
              <i class="fa fa-sitemap"></i>
              <div class="text-truncate">Résidences</div>
            </sidebar-link>
            <sidebar-link to="/app/mapping_site_ville">
              <i class="fa fa-sitemap"></i>
              <div class="text-truncate">Villes</div>
            </sidebar-link>
            <sidebar-link to="/app/mapping_site_residence_type">
              <i class="fa fa-exchange"></i>
              <div class="text-truncate">Types de résidences par sites</div>
            </sidebar-link>

            <sidebar-link class="d-none" to="/app/mapping_site_caracteristique">
              <i class="fa fa-sitemap"></i>
              <div class="text-truncate">Caractéristiques</div>
            </sidebar-link>
          </div>

          <hr />

          <h5 class="ml-2 pointer" data-toggle="collapse" data-target="#administration-menu">
            <i class="fa fa-caret-right"></i>
            Administration
          </h5>
          <div id="administration-menu" class="collapse show">
            <sidebar-link to="/app/app_monitoring">
              <i class="fa fa-stethoscope text-warning"></i>
              <div class="text-truncate">Monitoring des services</div>
            </sidebar-link>

            <sidebar-link to="/app/api_log">
              <i class="fa fa-list"></i>
              <div class="text-truncate">Logs d'api</div>
            </sidebar-link>
            <sidebar-link to="/app/api_key">
              <i class="fa fa-lock"></i>
              <div class="text-truncate">Clés d'api</div>
            </sidebar-link>

            <sidebar-link to="/app/user">
              <i class="fa fa-users"></i>
              <div class="text-truncate">Utilisateurs</div>
            </sidebar-link>
          </div>
        </template>
        <!--

      BLOCK

    -->

        <!--

     BLOCK

   -->
      </div>
      <!--

  BLOCK

      <div v-if="userHasRole('TGS')">
        <h5 class="ml-2">{{ $t('common.labels.sections') }}</h5>
        <sidebar-link v-for="model of $store.getters.automaticModels"
        :to="'/app/' + (model ? model.name : '')" :key="model.name">
          <i class="fa fa-caret-right"></i>
          <div>{{ $t('app.labels.' + (model.pluralName ?
           model.pluralName :
            (model.name + 's'))) }}</div>
        </sidebar-link>
      </div>

    -->

      <!--

  BLOCK

-->
      <div style="position: fixed; bottom: 1px; font-size: 10px; padding: 2px 5px; margin-bottom: 3px">
        Version: {{ config.version }}
        <br />
        Built: {{ config.buildDate }}
      </div>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import { rolesMixin } from 'vue-aw-components';
import config from '@/config';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },

  mixins: [rolesMixin],

  data() {
    return {
      config,
      providerCode: '',
    };
  },

  mounted() {
    this.loadDynamicPages();
    this.providerCode = this.$store.state.user && this.$store.state.user.providerCode;
  },

  methods: {
    userIs(role) {
      $store = this.$store;
      return $store.state.user.roles && $store.state.user.roles.indexOf(role) > -1;
    },

    userHasRole(role, $store = null) {
      $store = this.$store;
      return $store.state.userExtendedRoles && $store.state.userExtendedRoles.indexOf(role) > -1;
    },
    loadDynamicPages() {
      this.$store.dispatch('getModels').then(() => {});
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
