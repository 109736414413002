<template>
  <div>
    <div><b>{{item.name}}</b></div>
    <span v-if="item.description">{{ item.description }}</span>
    <abbr ><span class="badge badge-primary" :style="{'background-color': item.backgroundColor, 'color': item.color || 'white'}">{{item.type}}</span></abbr>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
};
</script>
