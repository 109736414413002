import { Datetime } from 'vue-datetime';
import fgInput from './components/UIComponents/Inputs/formGroupInput.vue';
import UploadButton from './components/UIComponents/Inputs/UploadButton.vue';
import DropDown from './components/UIComponents/Dropdown.vue';
import Stats from './components/UIComponents/Stats.vue';
import FieldDateTime from './components/UIComponents/fieldDateTime.vue';
import FieldTextEditor from './components/UIComponents/FieldTextEditor.vue';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('fg-input', fgInput);
    Vue.component('upload-button', UploadButton);
    Vue.component('drop-down', DropDown);
    Vue.component('stats', Stats);
    Vue.component('fieldDateTime', FieldDateTime);
    Vue.component('datetime', Datetime);
    Vue.component('fieldTextEditor', FieldTextEditor);
    if (!Vue.prototype.$notify && Vue.prototype.$awNotify) {
      console.log('set default notify', Vue.prototype.$notify);
      Vue.prototype.$notify = Vue.prototype.$awNotify;
    }
  },
};

export default GlobalComponents;
