<template>
  <div class="input-group">
    <wysiwyg :props="props" v-model="value"></wysiwyg>
  </div>
</template>

<script>
import VEC from 'vue-aw-components';

export default {
  mixins: [VEC.abstractField],
  data() {
    return {
      //
    };
  },
};
</script>

<style scoped>
  @import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
