<template>
  <div class="wrapper wrapper-full-page">
    <div
    class="full-page section-image"
    data-color="black"
    data-image="/static/img/cropped-screen.jpg"
    >
    <div class="content">
      <div class="container">
        <div class="col-md-4 col-sm-6 ml-auto mr-auto">
          <form class="form" @submit.prevent="submitForm()">
            <div class="card card-login" v-bind:class="{ 'card-hidden': !pageLoaded }">
              <div class="card-header">
                <h3 class="header text-center">{{ $t('common.labels.login') }}</h3>
              </div>
              <div class="card-body">
                <div class="card-body">
                  <div class="form-group">
                    <label>{{ $t('common.labels.email') }}</label>
                    <input
                    v-model="email"
                    name="email"
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    :placeholder="$t('common.placeholders.email')"
                    required
                    autocomplete="off"
                    >
                  </div>
                  <div class="form-group">
                    <label>{{ $t('common.labels.password') }}</label>
                    <input
                    v-model="password"
                    name="password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('common.placeholders.password')"
                    required
                    autocomplete="off"
                    >
                  </div>
                </div>
              </div>
              <div class="card-footer ml-auto mr-auto text-center">
                <button
                type="submit"
                class="btn btn-primary btn-wd mb-1 btn-block"
                >{{ $t('common.buttons.submit') }}</button>
                <br>
                <button
                v-if="config.googleAuth"
                type="button"
                class="btn btn-clear mr-auto btn-danger"
                @click="loginGoogle()"
                ><i class="fa fa-google"></i> {{ $t('common.buttons.google') }}
              </button>
            </div>
            <div class="clearfix text-center">
              « Problème de connexion ? Contactez dev@enyosolutions.com »
              <router-link
              v-if="config && config.displayOptions && config.displayOptions.register"
              to="/register"
              class="btn btn-simple mr-auto text-info"
              >{{ $t('common.labels.register') }}</router-link>
              <br>
              <forgot-password v-if="config && config.displayOptions && config.displayOptions.passwordReset"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
  class="full-page-background"
  style="background-image: url('https://www.toocamp.com/images/comparateur-camping.jpg')"
  />
</div>
</div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import { setTimeout } from 'timers';
import ForgotPassword from '@/pages/GeneralViews/ForgotPassword.vue';
import config from '@/config';

export default {
  components: {
    ForgotPassword,
  },
  mixins: [apiErrorsMixin],
  data() {
    return {
      email: null,
      password: null,
      pageLoaded: false,
      config,
    };
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 20);
    if (this.$route.path === '/auth/google/callback' && this.$route.query.code) {
      this.getGoogleToken();
    }
  },
  methods: {
    submitForm() {
      this.$http.post('/auth/admin_login', {
        email: this.email,
        password: this.password,
      })
        .then(this.postLogin)
        .catch(this.apiErrorCallback);
    },

    postLogin(res) {
      if (res.data) {
        if (res.data && res.data.user && res.data.user.isActive) {
          this.$store.commit('token', res.data.token);
          this.$store.dispatch('user', res.data.user);
          this.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
          this.$store.dispatch('getMarchands');
          setTimeout(() => {
            this.$awNotify({
              title: this.$t('common.messages.loginWelcome',
                {
                  name: `${res.data.user.firstname}
                ${res.data.user.lastname}`,
                }),
              type: 'success',
            });
            this.$router.push('/');
          }, 250);
          return;
        }
        this.$awNotify({
          title: this.$t('common.messages.no_access_account'),
          type: 'warning',
        });
      }
    },

    loginGoogle() {
      this.$http.get('/auth/google').then((res) => {
        window.location.href = res.data.body;
      });
    },

    getGoogleToken() {
      this.$http.post('/auth/google/callback', this.$route.query)
        .then(this.postLogin)
        .catch(this.apiErrorCallback);
    },

  },
};
</script>
