<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-xs-12 col-md-6 text-left p-1">
          Dernier refresh à <b class="">{{ lastRefreshedAtComputed }}</b>
        </div>
        <div class="col-xs-12 col-md-6 text-right pr-0">
          <button class="btn btn-primary btn-simple pr-0" @click="getStats()">
            <i class="fa fa-refresh"></i> Rafraichir toutes les stats
          </button>
          <date-range-picker
            v-if="false"
            class="form-group vgt-date-range pl-2"
            :placeholder="$t('common.field.start')"
            @update="getStats()"
            :locale-data="datePicker.locale"
            v-model="dateRange"
            :opens="'left'"
            :auto-apply="true"
          >
          </date-range-picker>
        </div>
      </div>
      <hr />

      <div class="row">

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/dispo-by-merchant"
            :title="'Dispos par marchands'"
            :columns="[
              { field: 'id_marchand', type: 'number' },
              { field: 'nom_marchand' },
              { field: 'site' },
              { field: 'value', label: '#', type: 'number' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'primary',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/dispo-for-thelis"
            :title="'Dispos thelis'"
            :columns="[
              { field: 'id_marchand', type: 'number' },
              { field: 'nom' },
              { field: 'site' },
              { field: 'value', label: '#', type: 'number' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'info',
              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/dispo-for-inaxel"
            :title="'Dispos Inaxel'"
            :columns="[
              { field: 'id_marchand', type: 'number' },
              { field: 'nom' },
              { field: 'site' },
              { field: 'value', label: '#', type: 'number' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'danger',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/dispo-for-ctouvert"
            :title="'Dispos CTOUVERT'"
            :columns="[
              { field: 'id_marchand', type: 'number' },
              { field: 'nom' },
              { field: 'site' },
              { field: 'value', label: '#', type: 'number' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'success',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin } from 'vue-aw-components';
import DateRangePicker from 'vue2-daterange-picker';
// import qs from 'qs';

export default {
  mixins: [rolesMixin],
  components: {
    DateRangePicker,
  },
  data() {
    return {
      generalStats: {},
      disposBySite: [],
      disposByMerchant: [],
      missingDailyStandupMembers: {
        date: null,
        columns: ['firstname', 'lastname', 'username'],
        data: [],
      },
      dateRange: {
        startDate: moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      lastRefreshedAt: null,
      graphNeedsRefresh: false,
      tableNeedsRefresh: false,
      elasticTableNeedsRefresh: false,
      statsRefreshHandle: null,
      currentMonth: moment().format('MM-YYYY'),
      currentYear: moment().format('YYYY'),
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },

      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 500,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      statsTable: {
        // columns: ['item', 'count', 'value'],
        columns: [
          {
            label: this.$t('app.projects.months'),
            field: 'item',
          },
          {
            label: this.$t('app.projects.contracts'),
            field: 'count',
          },
          {
            label: this.$t('app.projects.value'),
            field: 'valueInEuros',
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    this.getStats();
    try {
      const savedStats = JSON.parse(localStorage.getItem('overviewPage_generalStats'));
      if (savedStats && savedStats.columns && savedStats.data) {
        // this.statsTable = savedStats;
        // this.graphNeedsRefresh = true;
      }
    } catch (e) {
      console.warn(e);
    }

    let numberOfCalls = 0;
    this.statsRefreshHandle = setInterval(() => {
      if (numberOfCalls > 300) {
        this.$notify({ title: 'too much calls, aborting tracking', type: 'warning' });
        clearInterval(this.statsRefreshHandle);
        this.statsRefreshHandle = null;
        return;
      }
      if (!document.hasFocus()) {
        return;
      }
      numberOfCalls += 1;
      this.getStats();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.statsRefreshHandle);
  },

  computed: {
    lastRefreshedAtComputed() {
      return moment(this.lastRefreshedAt).format('dddd - DD MMM hh:mm:ss');
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return;
      }
      return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    },

    getStats() {
      this.elasticTableNeedsRefresh = true;
      this.tableNeedsRefresh = true;
      this.$http
        .get('/stats/overview')
        .then((res) => {
          this.disposBySite = res.data.body;
          this.tableNeedsRefresh = true;
        })
        .catch((err) => console.warn(err));
      this.lastRefreshedAt = new Date();
    },
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },

    sendToToocamp(body) {
      const { item } = body;
      // eslint-disable-next-line
      if (confirm('Are you sure ? ')) {
        this.$http
          .post(`/marchand/${item.id_marchand}/send-to-toocamp`, { site: item.site })
          .then(() => {
            this.$notify(`Marchand envoyé sur ${item.site}.toocamp.com`);
          })
          .catch(console.warn);
      }
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
