<template>
<!--
  <div></div>
-->
  <div class="wrapper wrapper-full-page">
    <div
      class="full-page section-image"
      data-color="black"
      data-image="/static/img/full-screen-image-2.jpg"
    >
      <div class="content">
        <div class="container">
          <div class="col-md-6 col-sm-6 ml-auto mr-auto">
            <form class="form" @submit.prevent="submitForm()">
              <div class="card card-login" v-bind:class="{ 'card-hidden': !pageLoaded }">
                <div class="card-header">
                  <h3 class="header text-center">{{ $t('common.labels.register') }}</h3>
                </div>
                <div class="card-body">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.firstname') }}</label>
                          <input
                            v-model="newUser.firstname"
                            name="firstname"
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.placeholders.firstname')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.lastname') }}</label>
                          <input
                            v-model="newUser.lastname"
                            name="lastname"
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.placeholders.lastname')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.email') }}</label>
                          <input
                            v-model="newUser.email"
                            name="email"
                            type="email"
                            class="form-control"
                            :placeholder="$t('common.placeholders.email')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.username') }}</label>
                          <input
                            v-model="newUser.username"
                            name="username"
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.placeholders.username')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.password') }}</label>
                          <input
                            v-model="newUser.password"
                            name="password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('common.placeholders.password')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $t('common.labels.confirmPassword') }}</label>
                          <input
                            v-model="newUser.cPassword"
                            name="cPassword"
                            type="password"
                            class="form-control"
                            :placeholder="$t('common.placeholders.confirmPassword')"
                            required
                            autocomplete="off"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer ml-auto mr-auto">
                  <button
                    type="submit"
                    class="btn btn-warning btn-wd"
                  >{{ $t('common.buttons.submit') }}</button>
                  <br>
                </div>
                <div class="clearfix text-center">
                  <router-link
                    to="/login"
                    class="btn btn-simple mr-auto text-info"
                  >{{ $t('common.labels.login') }}</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="full-page-background"
        style="background-image: url('/static/img/full-screen-image-2.jpg') "
      />
    </div>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';

export default {
  components: {
  },
  mixins: [apiErrorsMixin],
  data() {
    return {
      newUser: {
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        password: null,
      },
      pageLoaded: false,
    };
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 20);
  },
  methods: {
    submitForm() {
      if (this.newUser.password !== this.newUser.cPassword) {
        this.$notify({
          title: this.$t('common.messages.password_not_match'),
          type: 'warning',
        });
        return;
      }
      this.$http.post('/user', this.newUser).then((res) => {
        this.$store.dispatch('user', res.data.user);
        this.$store.commit('token', res.data.token);
        this.$store.dispatch('getMarchands');
        this.$router.push('/');
      })
        .catch(this.apiErrorCallback);
    },
  },
};

</script>
