<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-xs-12 col-md-6 text-left p-1">
          Dernier refresh à <b class="">{{ lastRefreshedAtComputed }}</b>
        </div>
        <div class="col-xs-12 col-md-6 text-right pr-0">
          <button class="btn btn-primary btn-simple pr-0" @click="getStats()">
            <i class="fa fa-refresh"></i> Rafraichir toutes les stats
          </button>
          <date-range-picker
            v-if="false"
            class="form-group vgt-date-range pl-2"
            :placeholder="$t('common.field.start')"
            @update="getStats()"
            :locale-data="datePicker.locale"
            v-model="dateRange"
            :opens="'left'"
            :auto-apply="true"
          >
          </date-range-picker>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-xl-6 col-md-6 counter-widget">
          <awesome-table
            :title="'Statut des services'"
            :columns="[
              { field: 'name', type: 'string' },
              { field: 'status', type: 'boolean' },
              { field: 'comments', label: 'commentaires' },
            ]"
            url="/app_monitoring?filters[type]=ping"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{
              headerStyle: 'info',
              perPage: 10,
            }"
            :actions="{ noActions: true, filter: false, refresh: true, delete: false, edit: false, view: true }"
            perPage="10"
            @customAction="sendToToocamp"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <awesome-table
            :title="'Statut des stocks en front'"
            :columns="[
              { field: 'name', type: 'string' },
              { field: 'status', type: 'boolean' },
              { field: 'comments', label: 'commentaires' },
            ]"
            url="/app_monitoring?filters[type][$ne]=ping"
            :needs-refresh.sync="tableNeedsRefresh"
            :options="{
              headerStyle: 'info',
              perPage: 10,
            }"
            :actions="{ noActions: true, filter: false, refresh: true, delete: false, edit: false, view: true }"
            perPage="20"
            @customAction="sendToToocamp"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <awesome-table
            url="/monitoring?filters[category]=synchro"
            :title="'Monitoring des synchros'"
            :columns="[
              { field: 'id', type: 'number' },
              { field: 'command' },
              { field: 'logLevel', type: 'text' },
              { field: 'createdOn', type: 'datetime' },
              { field: 'context' },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="20"
            :options="{
              headerStyle: 'info',
            }"
            :actions="{ noActions: true, filter: false, refresh: true }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>

        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-table-and-charts-card
            v-if="disposBySite && disposBySite.list"
            :title="'Dispos en base centrale'"
            :table-columns="[{ field: 'site' }, { field: 'value', type: 'number', label: '#', formatFn: numberFormat }]"
            :data-source="disposBySite.list"
            :needs-refresh.sync="tableNeedsRefresh"
            :table-options="{ headerStyle: 'primary', actions: { noActions: true, filter: false, refresh: true } }"
            :table-actions="{ noActions: true, filter: false, refresh: true }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'site'"
          />
        </div>
        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/dispos-in-elasticsearch"
            :title="'Statistiques des dispos dans Elasticsearch'"
            :columns="[
              { field: 'index' },
              { field: 'inDb', label: 'Nb total', type: 'number', formatFn: numberFormat },
              { field: 'validDate', label: 'Date de début valide', type: 'number', formatFn: numberFormat },
              { field: 'disabledForfait', label: 'Sans forfait', type: 'number', formatFn: numberFormat },
              { field: 'displayedOnWebsite', label: 'Visibles sur le site', type: 'number', formatFn: numberFormat },
            ]"
            :needs-refresh.sync="elasticTableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'danger',
            }"
            :actions="{ noActions: true, filter: false, refresh: true }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>
        <div class="col-xl-6 col-md-6 counter-widget">
          <enyo-ajax-table
            url="/stats/import-with-no-dispos"
            :title="'Imports potentiellement en erreur'"
            :columns="[
              { field: 'id_marchand', type: 'number' },
              { field: 'nom' },
              { field: 'meta_marchand', type: 'number' },
              { field: 'date_import_debut', type: 'datetime' },
              { field: 'duree_exec' },
              { field: 'nb_produits', type: 'number', formatFn: numberFormat },
              { field: 'nb_dispos', type: 'number', formatFn: numberFormat },
            ]"
            :needs-refresh.sync="tableNeedsRefresh"
            perPage="10"
            :options="{
              headerStyle: 'danger',

              actions: { noActions: true, filter: false, refresh: true },
            }"
            :chart-options="{ headerStyle: 'primary' }"
            :chart-col-key="'id_marchand'"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-6 counter-widget" v-if="false">
          <enyo-card v-if="generalStats">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot>
              <p class="card-category">{{ $t('app.labels.sales_income') }} Total</p>
              <h3 class="card-title">{{ formatNumber(generalStats.incomeTotal) }} €</h3>
            </div>
            <div slot="footer"></div>
          </enyo-card>
        </div>
        <div class="col-xl-3 col-md-6 counter-widget" v-if="false">
          <enyo-card v-if="generalStats">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot>
              <p class="card-category">{{ $t('app.labels.sales_income') }} {{ currentYear }}</p>
              <h3 class="card-title">{{ formatNumber(generalStats.incomeCurrentYear) }} €</h3>
            </div>
            <div slot="footer" class="text-right">Objectif <b class="text-success">360.000€</b></div>
          </enyo-card>
        </div>
        <div class="col-xl-3 col-md-6 counter-widget">
          <enyo-card v-if="false">
            <div slot="header" class="icon-warning">
              <i class="fa fa-euro-sign text-warning"></i>
            </div>
            <div slot>
              <p class="card-category">{{ $t('app.labels.sales_income') }} {{ currentMonth }}</p>
              <h3 class="card-title">{{ generalStats.incomeCurrentMonth }} €</h3>
            </div>
            <div slot="footer">Objectif <b class="text-success">30.000€</b></div>
          </enyo-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin } from 'vue-aw-components';
import DateRangePicker from 'vue2-daterange-picker';
// import qs from 'qs';

export default {
  mixins: [rolesMixin],
  components: {
    DateRangePicker,
  },
  data() {
    return {
      generalStats: {},
      disposBySite: [],
      disposByMerchant: [],
      missingDailyStandupMembers: {
        date: null,
        columns: ['firstname', 'lastname', 'username'],
        data: [],
      },
      dateRange: {
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      lastRefreshedAt: null,
      graphNeedsRefresh: false,
      tableNeedsRefresh: false,
      elasticTableNeedsRefresh: false,
      currentMonth: moment().format('MM-YYYY'),
      currentYear: moment().format('YYYY'),
      datePicker: {
        locale: {
          direction: 'ltr', // direction of text
          format: 'DD-MM-YYYY', // fomart of the dates displayed
          separator: ' - ', // separator between the two ranges
          applyLabel: 'Appliquer',
          cancelLabel: 'Annuler',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
          monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
          firstDay: 1, // ISO first day of week - see moment documenations for details
        },
      },

      lineChart: {
        data: {
          labels: [],
          series: [],
        },
        options: {
          low: 0,
          high: 500,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      statsTable: {
        // columns: ['item', 'count', 'value'],
        columns: [
          {
            label: this.$t('app.projects.months'),
            field: 'item',
          },
          {
            label: this.$t('app.projects.contracts'),
            field: 'count',
          },
          {
            label: this.$t('app.projects.value'),
            field: 'valueInEuros',
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    this.getStats();
    try {
      const savedStats = JSON.parse(localStorage.getItem('overviewPage_generalStats'));
      if (savedStats && savedStats.columns && savedStats.data) {
        // this.statsTable = savedStats;
        // this.graphNeedsRefresh = true;
      }
    } catch (e) {
      console.warn(e);
    }
  },

  beforeDestroy() {},

  computed: {
    lastRefreshedAtComputed() {
      return moment(this.lastRefreshedAt).format('dddd - DD MMM hh:mm:ss');
    },
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return;
      }
      return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    },

    getStats() {
      this.elasticTableNeedsRefresh = true;
      this.tableNeedsRefresh = true;
      this.$http
        .get('/stats/overview')
        .then((res) => {
          this.disposBySite = res.data.body;
          this.tableNeedsRefresh = true;
        })
        .catch((err) => console.warn(err));
      this.lastRefreshedAt = new Date();
    },
    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },

    sendToToocamp(body) {
      const { item } = body;
      // eslint-disable-next-line
      if (confirm('Êtes-vous de vouloir effectuer cette action ? ')) {
        this.$http
          .post(`/marchand/${item.id_marchand}/send-to-toocamp`, { site: item.site })
          .then(() => {
            this.$notify(`Marchand envoyé sur ${item.site}.toocamp.com`);
          })
          .catch(console.warn);
      }
    },

    numberFormat(data) {
      return new Intl.NumberFormat('de-DE').format(data);
    },
  },
};
</script>
<style lang="scss">
#overviewPage {
  .contracts-table {
    .card-body {
      height: 343px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  h3.aw-table-header {
    font-size: 18px;
  }

  .ajax-table-col-logLevel {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &.ajax-table-col-value-success {
      color: #212529;
      background-color: #87cb16;
    }

    &.ajax-table-col-value-danger {
      color: #212529;
      background-color: #ff4a55;
    }

    &.ajax-table-col-value-info {
      color: #fff;
      background-color: #999;
    }
  }
}
</style>
