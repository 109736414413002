<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  >

    <div class="sidebar-wrapper">
      <div class="logo">
        <router-link :to="{path:'/'}" class="simple-text">
          <div class="logo-img">
            <img :src="appLogo" alt="">
          </div>
          <template v-if="titleLogo">
          <img :src="titleLogo" styl="" style="
              height: 21px;
              display: inline-flex;
          ">
        </template>
        <template v-else>
          <span class="text-light">{{ title }}</span>
        </template>
        </router-link>
      </div>
      <slot name="content"></slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link,index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            @click="closeNavbar"
            :link="link"
          >
            <i :class="link.icon"></i>
            <p>{{link.name}}</p>
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import config from '@/config';
import SidebarLink from './SidebarLink.vue';

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: config.appName,
    },
    appLogo: {
      type: String,
      default: config.appLogo,
    },
    titleLogo: {
      type: String,
      default: config.titleLogo,
    },
    backgroundColor: {
      type: String,
      default: process.env.VUE_APP_MODE === 'VISION' ? 'red' : 'black',
      validator: (value) => {
        const acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: '/static/img/bg_splash.jpg',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
};

</script>
<style>
</style>
