<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{path:'/app'}">{{ $t('common.labels.dashboard') }}</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ $t('common.labels.codedWith') }}
        <i class="fa fa-heart heart"></i>by
        <a href="https://www.enyosolutions.com" class="" target="_blank">Enyosolutions</a>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {};

</script>
<style>
</style>
