<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" @click="goToDashboard()" href="/">{{ $t('common.labels.dashboard') }}</a>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{toggled: $sidebar.showSidebar}"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto" style="width: 70%; margin: auto" >
                <li class="ml-2  nav-item nav-autocomplete" :class="{'full-search-bar':searchbarIsFocused}"
                style="width: 100%;"
                >
              <v-autocomplete :items="items" v-model="item" :auto-select-one-item="false" :min-len="1" :get-label="getLabel" @update-items="updateItems" @item-selected="itemSelected" :placeholder="$t('common.field.search')" :component-item="template"
              @focus="searchbarIsFocused = true"
              @blur="clearFocus"
              id="searchBar"
              >
              </v-autocomplete>
              <button class="btn btn-simple  btn-close-search-bar" @click="closeSearch()"><i class="fa fa-times"></i></button>
      </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown">
              <i class="nc-icon nc-palette"></i>
            </a>
          </li>
          <drop-down tag="li">
            <template slot="title">
              <i class="nc-icon nc-planet"></i>
              <b class="caret"></b>
              <span class="notification">0</span>
            </template>
            <a class="dropdown-item" href="#">...</a>

          </drop-down>

          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nc-icon nc-zoom-split"></i>

              <span class="d-lg-block">&nbsp;Search</span>
            </a>
          </li> -->
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link
              :to="{path:'/app/profile'}"
              class="nav-link text-info user-details"
              v-if="$store.state.user.id"
            >{{ $store.state.user.firstname + ' ' + $store.state.user.lastname }}</router-link>

          </li>
          <li class="nav-item">
            <a href="javascript:void(0);" id="logout" class="logout nav-link" @click="logout()">
              <i class="nc-icon nc-button-power"></i>
              <span style="margin-left: 5px;">{{ $t('common.labels.logout') }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>

import Autocomplete from 'v-autocomplete';
import _ from 'lodash';
import AutocompleteItem from './AutocompleteItem.vue';

export default {
  components: {
    'v-autocomplete': Autocomplete,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    appRoutes() {
      return this.$router.options.routes.find(r => r.path === '/app').children.filter(r => r.path.indexOf(':') === -1).map(r => ({
        type: 'section',
        backgroundColor: '#17a2b8',
        code: r.name.toLowerCase(),
        name: _.startCase(r.name),
        drescription: `Menu ${_.startCase(r.name)}`,
        url: `/app/${r.path}`,
      }));
    },
  },
  data() {
    return {
      activeNotifications: false,
      item: null,
      items: [],
      template: AutocompleteItem,
      searchbarIsFocused: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.onKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyPress);
  },
  methods: {
    onKeyPress(event, data) {
      if (event.type !== 'keydown'

      || !(event.target.tagName === document.body.tagName)
      ) {
        return;
      }
      if (
        ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.which === 70 || event.which === 80))
       || ((event.ctrlKey || event.metaKey) && event.shiftKey && event.which === 70)
      ) {
        const fieldRef = document.querySelector('#searchBar  .v-autocomplete-input');
        this.item = null;
        fieldRef.click();
        fieldRef.focus();
        event.preventDefault();
        this.$forceUpdate();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    goToDashboard() {
      this.$router.push('/');
    },

    getLabel(item) {
      return item ? item.name : item;
    },
    updateItems(text) {
      this.items = [];
      this.searchRoute(text);
      this.searchMarchands(text);
      this.searchConf(text);
    },
    searchRoute(text) {
      if (!text) {
        return;
      }
      const textLower = text.toLowerCase();
      this.items = this.items.concat(this.appRoutes.filter(r => r.code.indexOf(textLower) > -1));
    },
    searchMarchands(text) {
      this.$http.get(`/marchand?search=${text}`).then((response) => {
        response.data.body.forEach(item => {
          this.items.push({
            id: item.id,
            name: item.nom,
            description: `Fiche du marchand ${item.nom}`,
            type: 'marchand',
            url: `/app/marchand/${item.id}`,
            backgroundColor: 'red',
          });
          this.items.push({
            id: item.id,
            name: item.nom,
            description: `Rapport sherlock ${item.nom}`,
            type: 'sherlock',
            url: `/app/sherlock/${item.id}`,
            backgroundColor: 'lightblue',
          });
          this.items.push({
            id: item.id,
            name: item.nom,
            description: `process d'import du marchand ${item.nom}`,
            type: 'processes',

            url: `/app/processus?filters[filters][idMarchand]=${item.id}`,
            backgroundColor: 'green',
          });
        });
      }).catch(console.warn);
    },

    searchConf(text) {
      this.$http.get(`/flux_marchand_conf?search=${text}`).then((response) => {
        const items = response.data.body.map(item => ({
          id: item.id,
          name: `${item.nomMarchand}`,
          description: '',
          type: 'flux_marchand_conf',
          url: `/app/flux_marchand_conf/${item.id}`,
          backgroundColor: 'teal',
        }));
        this.items = this.items.concat(items);
      }).catch(console.warn);
    },

    itemSelected(item) {
      this.searchbarIsFocused = false;
      switch (item.type) {
        case 'deployment':
          this.item = null;
          this.$router.push(`/app/deployment/${item._id}`);
          break;
        default:
          this.item = null;
          this.$router.push(item.url);
          break;
      }
    },

    closeSearch() {
      this.item = null;
      this.searchbarIsFocused = false;
    },

    clearFocus() {
      setTimeout(() => {
        this.searchbarIsFocused = false;
      }, 350);
    },
  },
};

</script>
<style scoped>

.nav-autocomplete {
  transition: all 50ms linear;
}

.btn-close-search-bar {
  display: none;
}

.full-search-bar .btn-close-search-bar {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.5em;
  margin: 0px 5px;
  padding: 1px 5px;
}
</style>
