var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",attrs:{"id":"overviewPage"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-xs-12 col-md-6 text-left p-1"},[_vm._v(" Dernier refresh à "),_c('b',{},[_vm._v(_vm._s(_vm.lastRefreshedAtComputed))])]),_c('div',{staticClass:"col-xs-12 col-md-6 text-right pr-0"},[_c('button',{staticClass:"btn btn-primary btn-simple pr-0",on:{"click":function($event){return _vm.getStats()}}},[_c('i',{staticClass:"fa fa-refresh"}),_vm._v(" Rafraichir toutes les stats ")]),(false)?_c('date-range-picker',{staticClass:"form-group vgt-date-range pl-2",attrs:{"placeholder":_vm.$t('common.field.start'),"locale-data":_vm.datePicker.locale,"opens":'left',"auto-apply":true},on:{"update":function($event){return _vm.getStats()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}):_vm._e()],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-md-6 counter-widget"},[_c('enyo-ajax-table',{attrs:{"url":"/stats/dispo-by-merchant","title":'Dispos par marchands',"columns":[
            { field: 'id_marchand', type: 'number' },
            { field: 'nom_marchand' },
            { field: 'site' },
            { field: 'value', label: '#', type: 'number' } ],"needs-refresh":_vm.tableNeedsRefresh,"perPage":"10","options":{
            headerStyle: 'primary',

            actions: { noActions: true, filter: false, refresh: true },
          },"chart-options":{ headerStyle: 'primary' },"chart-col-key":'id_marchand'},on:{"update:needsRefresh":function($event){_vm.tableNeedsRefresh=$event},"update:needs-refresh":function($event){_vm.tableNeedsRefresh=$event}}})],1),_c('div',{staticClass:"col-xl-6 col-md-6 counter-widget"},[_c('enyo-ajax-table',{attrs:{"url":"/stats/dispo-for-thelis","title":'Dispos thelis',"columns":[
            { field: 'id_marchand', type: 'number' },
            { field: 'nom' },
            { field: 'site' },
            { field: 'value', label: '#', type: 'number' } ],"needs-refresh":_vm.tableNeedsRefresh,"perPage":"10","options":{
            headerStyle: 'info',
            actions: { noActions: true, filter: false, refresh: true },
          },"chart-options":{ headerStyle: 'primary' },"chart-col-key":'id_marchand'},on:{"update:needsRefresh":function($event){_vm.tableNeedsRefresh=$event},"update:needs-refresh":function($event){_vm.tableNeedsRefresh=$event}}})],1),_c('div',{staticClass:"col-xl-6 col-md-6 counter-widget"},[_c('enyo-ajax-table',{attrs:{"url":"/stats/dispo-for-inaxel","title":'Dispos Inaxel',"columns":[
            { field: 'id_marchand', type: 'number' },
            { field: 'nom' },
            { field: 'site' },
            { field: 'value', label: '#', type: 'number' } ],"needs-refresh":_vm.tableNeedsRefresh,"perPage":"10","options":{
            headerStyle: 'danger',

            actions: { noActions: true, filter: false, refresh: true },
          },"chart-options":{ headerStyle: 'primary' },"chart-col-key":'id_marchand'},on:{"update:needsRefresh":function($event){_vm.tableNeedsRefresh=$event},"update:needs-refresh":function($event){_vm.tableNeedsRefresh=$event}}})],1),_c('div',{staticClass:"col-xl-6 col-md-6 counter-widget"},[_c('enyo-ajax-table',{attrs:{"url":"/stats/dispo-for-ctouvert","title":'Dispos CTOUVERT',"columns":[
            { field: 'id_marchand', type: 'number' },
            { field: 'nom' },
            { field: 'site' },
            { field: 'value', label: '#', type: 'number' } ],"needs-refresh":_vm.tableNeedsRefresh,"perPage":"10","options":{
            headerStyle: 'success',

            actions: { noActions: true, filter: false, refresh: true },
          },"chart-options":{ headerStyle: 'primary' },"chart-col-key":'id_marchand'},on:{"update:needsRefresh":function($event){_vm.tableNeedsRefresh=$event},"update:needs-refresh":function($event){_vm.tableNeedsRefresh=$event}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }