/* eslint-disable import/no-unresolved */

// unused pages
// Admin pages

import EnyoVueComponents from 'vue-aw-components';
import _ from 'lodash';
import Overview from '@/components/Dashboard/Views/Overview.vue';

import MarchandsOverview from '@/components/Dashboard/Views/MarchandsOverview.vue';

import Profile from '@/pages/user/Profile.vue';
import Login from '@/pages/GeneralViews/Login.vue';
import NotFound from '@/pages/GeneralViews/NotFoundPage.vue';
import Register from '@/pages/GeneralViews/Register.vue';
import Reset from '@/pages/GeneralViews/Reset.vue';
import Sherlock from '@/pages/sherlock/Sherlock.vue';
import MatchingStats from '@/pages/MatchingStats/MatchingStats.vue';
import Debugger from '@/pages/sherlock/Debugger.vue';
import Stock from '@/pages/stock/Stock.vue';
import EditorialPage from '@/pages/residence/editorialPage'

/* eslint-enable import/no-unresolved */
import config from '@/config';
import rolesMixin from '@/mixins/rolesMixin';
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue';

// GeneralViews
import store from '../stores';

const { CrudComponent } = EnyoVueComponents;

const authGuard = (to, from, next) => {
  if (store.state.user && store.state.user[config.primaryKey]) {
    return next();
  }
  console.warn('Authgard:: Blocked : ', to.path, store.state.user);
  next('/login');
};

// eslint-disable-next-line
const roleGuard = role => (to, from, next) => {
  if (!store.state.user || !store.state.user[config.primaryKey]) {
    console.warn('Rolegard::', 'User not connected, forcing logout');
    store.commit('logout');
    return next('/login');
  }

  if (!store.state.user.roles || !rolesMixin.methods.userHasRole(role, store)) {
    next('/');
    console.warn('Rolegard:: missing role ', role, 'redirecting');
    return;
  }
  return next();
};

const routes = [{
  path: '/login',
  name: 'Login',
  component: Login,
},
{
  path: '/auth/google/callback',
  name: 'Google',
  component: Login,
},
{
  path: '/register',
  name: 'Register',
  component: Register,
},
{
  path: '/reset-password/:token',
  name: 'Reset',
  component: Reset,
  props: true,
},
{
  path: '/',
  name: 'Home',
  redirect: '/app/overview/home',
},
{
  path: '/app',
  component: DashboardLayout,
  redirect: '/app/overview/home',
  beforeEnter: authGuard,
  children: [{
    path: 'overview/home',
    name: 'Overview',
    component: Overview,
    beforeEnter: authGuard,
  },
  {
    path: 'overview/marchands',
    name: 'OverviewMarchands',
    component: MarchandsOverview,
  },
  {
    path: 'overview/stocks',
    name: 'Stock',
    component: Stock,
  },
  {
    path: 'sherlock',
    name: 'Sherlock',
    component: Sherlock,
  },
  {
    path: 'debugger',
    name: 'Debugger',
    component: Debugger,
  },
  {
    path: 'sherlock/:idMarchand',
    name: 'Sherlock_details',
    component: Sherlock,
  },
  {
    path: 'matching/stats',
    name: 'Matching',
    component: MatchingStats,
  },
  {
    path: 'profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    name: 'api_log',
    path: 'api_log',
    component: CrudComponent,
    beforeEnter: authGuard,
    props: {
      identity: 'api_log',
      mode: 'remote',
      options: {
        url: 'crud/api_log',
        stats: true,
        actions: {
          view: true,
          delete: true,
        },
      },
    },
    children: [{
      name: 'api_log-view',
      path: ':id',
      mode: 'remote',
      component: CrudComponent,
      props: {
        identity: 'api_log',
        options: {
          actions: {
            view: true,
          },
        },
      },
    }],
  },
  {
    path: 'crud/:identity',
    name: 'Crud Page',
    component: CrudComponent,
    props: true,
    children: [{
      name: 'crud-view',
      path: ':id',
      component: CrudComponent,
      props: true,
    },
    {
      name: 'crud-edit',
      path: ':id/edit',
      component: CrudComponent,
      props: true,
    },
    ],
  },
  {
    name: "editorialPage",
    path: "residence/:id/editorial",
    component: EditorialPage
  }
  ],
},
{
  path: '*',
  component: NotFound,
},
];

if (config.automaticModels && config.automaticModels.length) {
  const appRoute = routes.find(route => route.path === '/app');
  config.automaticModels.forEach((model) => {
    const options = {
      mode: 'remote',
      url: null,
      columns: null,
      viewPath: null,
      stats: false,
      modalMode: 'slide', // fade | slide
      nestedDisplayMode: 'list', // list | object
    };
    const defaultActions = {
      noActions: false,
      create: false,
      edit: false,
      filter: true,
      dateFilter: true,
      view: true,
      delete: false,
      import: false,
      export: true,
    };
    appRoute.children.push({
      name: model.name,
      path: model.route || model.name,
      component: CrudComponent,
      beforeEnter: authGuard,
      meta: {
        identity: model.identity || model.modelName || model.name,
      },
      props: {
        ...model,
        name: model.name,
        title: model.title || model.pluralName,
        identity: model.identity || model.modelName || model.name,
        options: _.merge(options, model.options, model.listOptions),
        actions: _.merge(defaultActions, model.actions, model.options.actions),
        nestedModels: model.nestedModels,
        nestedDisplayMode: 'object',
      },
      children: [{
        name: `${model.name}-view`,
        path: ':id',
        component: CrudComponent,
        meta: {
          identity: model.identity || model.modelName || model.name,
        },
        props: {
          ...model,
          name: model.name,
          title: model.title || model.pluralName,
          identity: model.identity || model.modelName || model.name,
          options: _.merge(options, model.options, model.viewOptions),
          actions: _.merge(defaultActions, model.actions, model.options.actions),
          nestedModels: model.nestedModels,
          nestedDisplayMode: 'object',
        },
      }, {
        name: `${model.name}-edit`,
        path: ':id/edit',
        component: CrudComponent,
        meta: {
          identity: model.identity || model.modelName || model.name,
        },
        props: {
          ...model,
          name: model.name,
          title: model.title,
          identity: model.identity || model.modelName || model.name,
          options: _.merge(options, model.options, model.editOptions),
          actions: _.merge(defaultActions, model.actions, model.options.actions),
          nestedModels: model.nestedModels,
          nestedDisplayMode: 'object',
        },
      }],
    });
  });
}

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
         var res= require('../components/Dashboard/Views/' + name + '.vue');
         return res;
       };* */

export default routes;
export { authGuard, roleGuard };
