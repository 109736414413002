import VTooltip from 'v-tooltip';
// Notifications plugin
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
// import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from './components/UIComponents/SidebarPlugin';

// asset imports
import './assets/sass/theme/_variables.scss';
import './assets/sass/main.scss';
import './assets/css/demo.css';

// library auto imports
import 'es6-promise/auto';

/**
 * This is the main Light Bootstrap Dashboard Vue plugin
  where dashboard related plugins are registerd.
 */
export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    // Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(VTooltip);
  },
};
