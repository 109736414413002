import moment from 'moment';
import _ from 'lodash';
import pluralize from 'pluralize';

export default {
  join: (value, glue, options) => {
    if (!value || value.length === 0) {
      return '';
    }
    if (options && options.startCase) {
      value = value.map(elm => _.startCase(elm));
    }
    return value.join(glue);
  },
  startCase: (value) => {
    if (!value || value.length === 0) {
      return '';
    }
    return _.startCase(value);
  },

  date: (value, format) => {
    if (!value || value.length === 0) {
      return '';
    }
    return moment(value).format(format || 'DD/MM/YY à hh[h]mm');
  },

  pluralize: (value, count) => {
    if (!value || value.length === 0) {
      return '';
    }
    return pluralize(value, count);
  },

  formatDate: (value) => {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY hh:mm');
    }
    return value;
  },

};
