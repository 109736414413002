<template>
  <div class="content" id="overviewPage">
    <h2>Requetes de matching</h2>
    <div class="container-fluid">
      <form @submit.prevent="search()">
        <div class="row m-0">
          <div class="col-sm-6 col-xs-12 text-left p-1">
            <VueSelect
              class="bg-white form-vue-select"
              placeholder="Filtrer sur un type"
              :options="[
                'flux_match_villes_log',
                'flux_match_residences_log',
                'flux_match_flux_residence_associations_log',
              ]"
              :multi="true"
              :multiple="true"
              :taggable="true"
              v-model="index"
              @input="searchDebounced"
            />
          </div>
          <div class="col-sm-6 col-xs-12 text-left p-1">
            <VueSelect
              class="bg-white form-vue-select"
              placeholder="Filtrer par matching réussi"
              :options="['true', 'false']"
              v-model="searchFound"
              @input="searchDebounced"
            />
          </div>
        </div>
      </form>
      <hr />

      <div class="row">
        <div class="col-xs-12 col-md-3 text-right">
          <awesomeTable
            title="Stats de matching en erreurs"
            v-if="aggregations && aggregations.length"
            :rows="aggregations"
            :columns="['key', 'doc_count']"
            :perPage="100"
            @onRowClicked="viewStat"
            :actions="{ noActions: true, exportLocal: true, pagination: true, view: true }"
          />
        </div>
        <div class="col-xs-12 col-md-9">
          <awesomeTable
            title="detail"
            :key="filterNom"
            :url="detailsUrl"
            :columns="cols"
            mode="remote"
            @after-api-refresh="afterApiRefresh"
            :actions="{ dateFilter: false, search: true, refresh: true, columnsFilters: false, filter: true }"
            :options="{ filterInitiallyOn: false }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { rolesMixin } from 'vue-aw-components';
import VueSelect from 'vue-select';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
// import qs from 'qs';

export default {
  name: 'Debugger',
  mixins: [rolesMixin],
  components: { VueSelect },
  data() {
    return {
      searchInput: '',
      searchFound: null,
      index: [],
      aggregations: [],
      filterNom: '',
      refreshTs: '',
      cols: [
        { field: '_index', type: 'text', filterOptions: { filterable: false, trigger: 'enter' } },
        '_source.@timestamp',
        '_source.nom',
        { field: '_source.found', type: 'boolean', enum: [true, false] },
        {
          field: '_source.hits',
          type: 'number',
          isArray: true,
          filterOptions: { enabled: false },
          filterable: false,
        },
        { field: '_source.hitsFound', type: 'object', isArray: true },
        '_source.idMarchand',
        '_source.request',
        '_source.result',
      ],
    };
  },
  mounted() {
    this.searchDebounced = _.debounce(this.search);
    try {
    } catch (e) {
      console.warn(e);
    }
  },

  beforeDestroy() {},

  computed: {
    queryParams() {
      return { index: [...this.index] };
    },
    detailsUrl() {
      return `/matching/stats?index=${this.index}&nom=${this.filterNom || ''}${
        this.searchFound !== undefined && this.searchFound !== null ? `&filters[_source.found]=${this.searchFound}` : ''
      }`;
    },
  },
  methods: {
    searchDebounced() {},
    search(index) {
      console.log('search', index);
      if (index) {
        //   this.index = [...index];
      }
    },

    afterApiRefresh({ response }) {
      console.log('afterApiRefresh on detail page');
      this.aggregations = response.data.aggregations;
      console.log('response', response);
    },

    viewStat(event, arg2) {
      const { row, column } = event;
      if (column.field === 'key') {
        this.filterNom = row.key;
      }
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.form-vue-select {
  height: 100%;
  .vs__dropdown-toggle {
    height: 100%;
  }
}
</style>
