import _ from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2';

async function sendToSite(item, dropdownItem, context, route) {
  if (await context.$awConfirm('Êtes-vous sur de vouloir effectuer cette action ?')) {
    context.$http.post(`${route}/send-to-site`, {
      targetId: item.id,
      site: dropdownItem,
    })
      .then(async (response) => {
        if (response && response.data) {
          if (response.data.success) {
            return context.$notify(`L'élément a été envoyé sur ${dropdownItem.name}.`);
          }
          return context.$notify({
            title: `Erreur lors de l'envoi de l'élément sur ${dropdownItem.name}.`,
            footer: response.data.message || 'error_server',
            message: response.data.message || 'error_server',
            type: 'error',
          });
        }
      })
      .catch((error) => {
        context.$notify({
          title: `Erreur lors de l'envoi de l'élément sur ${dropdownItem.name}.`,
          //   footer: error,
          message: (error && error.data && error.data.message) || error || 'error_server',
          footer: (error && error.data && error.data.message) || error || 'error_server',
          type: 'error',
        });
      });
  }
}

export default [
  {
    name: 'api_log',
    icon: '',
    options: {
      stats: true,
      dataPaginationMode: 'remote',
      actions: { create: false, edit: false, delete: true },
    },
  },
  {
    name: 'api_key',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'local',
      actions: { create: true, edit: true, delete: true },
    },
  },
  {
    name: 'residence_association_manuelle',
    icon: '',
    options: {
      uploadUrl: '/residence_association_manuelle/import',
      stats: false,
      dataPaginationMode: 'remote',
      filterInitiallyOn: true,
      actions: {
        create: true, edit: true, delete: true, import: true, export: true,
      },
      columnsDisplayed: 14,
      customTopRightActions: [
        {
          name: 'missing-associations',
          label: 'Afficher les associations manquantes',
          classes: 'btn-warning',
          title: 'Afficher uniquement les associations manquantes',
          icon: 'fa fa-question',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$router.replace({
              path: '',
              query: { filters: { filters: { idResidence: '$ISNULL' } }, timestamp: Date.now() },
            });
          },
        },
      ],
      customInlineActions: [
        {
          name: 'new-import-job',
          label: '',
          classes: 'btn-simple btn-success',
          title: 'Lancer le flux',
          icon: 'fa fa-play',

          action: async ({
            item, action, location, props, id,
          }, context) => {
            // eslint-disable-next-line
            if (await context.confirm('Lancer le flux ? ')) {
              context.$http.post(`/processus/start-flux/${item.idMarchand}/${item.idMarchandOwner}`, {}).then(() => {
                context.$notify(`L\'import du flux [${item.nom}] à bien été lancé.`);
              }).then(() => context.$notify({
                title: `Erreur lors de l\'import du flux [${item.nom}].`,
                type: 'error',
              }));
            }
          },
        },
      ],
    },
  },
  {
    name: 'residence_association',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      filterInitiallyOn: true,
      columnsDisplayed: 15,
      actions: { create: true, edit: true, delete: true },

      customTopRightActions: [
        {
          name: 'missing-associations',
          label: 'Afficher les associations manquantes',
          classes: 'btn-warning',
          title: 'Afficher uniquement les associations manquantes',
          icon: 'fa fa-question',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$router.replace({
              path: '',
              query: { filters: { filters: { idResidence: '$ISNULL' } }, timestamp: Date.now() },
            });
          },
        },
      ],
    },
  },
  {
    name: 'processus',
    icon: '',
    segmentField: 'statut',
    options: {
      stats: true,
      dataPaginationMode: 'remote',
      detailPageMode: 'page',
      autoRefresh: true,
      autoRefreshInterval: 10,
      customTopRightActions: [
        {
          name: 'process-worker-decrease',
          label: 'Moins de workers',
          classes: 'btn-warning',
          title: 'Réduire le nombre de workers en parallèle',
          icon: 'fa fa-minus',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$http.put('/processus/decrease-workers', {}).then(() => {
              context.refreshComponent();
            });
          },
        },
        {
          name: 'process-worker-increase',
          label: 'Plus de workers',
          classes: 'btn-success',
          title: 'Augmenter le nombre de workers en parallèle',
          icon: 'fa fa-plus',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$http.put('/processus/increase-workers', {}).then(() => {
              context.refreshComponent();
            });
          },
        },
        {
          name: 'process-worker-decrease',
          label: 'Moins de workers<br/>Gros Marchand',
          classes: 'btn-warning',
          title: 'Réduire le nombre de workers Gros Marchand en parallèle',
          icon: 'fa fa-minus',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$http.put('/processus/decrease-large-workers', {}).then(() => {
              context.refreshComponent();
            });
          },
        },
        {
          name: 'process-worker-increase',
          label: 'Plus de workers<br/>Gros Marchand',
          classes: 'btn-success',
          title: 'Augmenter le nombre de workers Gros Marchand en parallèle',
          icon: 'fa fa-plus',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$http.put('/processus/increase-large-workers', {}).then(() => {
              context.refreshComponent();
            });
          },
        },
        {
          name: 'process-scoring-create',
          label: 'Lancer le scoring de tous les flux',
          classes: 'btn-info',
          title: 'Lancer le scoring de tous les flux',
          icon: 'fa fa-play',
          action: async ({
            items, action, location, props, id,
          }, context) => {
            if (await context.confirm('Lancer le scoring de tous les flux ?')) {
              context.$http.post('/processus/scoring-jobs', {}).then(() => {
                context.refreshComponent();
              });
            }
          },
        },
        {
          name: 'process-list-create',
          label: 'Lancer tous les flux',
          classes: 'btn-info',
          title: 'Lancer tous les flux',
          icon: 'fa fa-play',
          action: async ({
            items, action, location, props, id,
          }, context) => {
            // eslint-disable-next-line
            if (await context.confirm('Lancer l\'import de tous les flux ?')) {
              context.$http.post('/processus/create-jobs', {}).then(() => {
                context.refreshComponent();
              });
            }
          },
        },
        {
          name: 'process-list-manual-create',
          label: 'Lancer les flux à<br/>association manuelles',
          classes: 'btn-info',
          title: 'Lancer les flux association manuelles',
          icon: 'fa fa-play',
          sticky: true,
          action: async ({
            items, action, location, props, id,
          }, context) => {
            // eslint-disable-next-line
            if (await context.confirm('Lancer l\'import des flux associés ?')) {
              context.$http.post('/processus/create-manual-jobs', {}).then(() => {
                context.refreshComponent();
              });
            }
          },
        },
        {
          name: 'process-list-filter-failed',
          label: 'Voir les imports échoués',
          classes: 'btn-danger',
          title: 'Voir les imports échoués',
          icon: 'fa fa-exclamation-triangle',
          field: 'gogolito.id',
          action: ({
            items, action, location, props, id,
          }, context) => {
            context.$router.push({
              path: '/app/processus',
              query: { filters: { filters: { statut: 'failed' } } },
            });
          },
        },
      ],
      filterInitiallyOn: true,
      columnsDisplayed: 11,
      actions: { create: true, edit: true, delete: true },
    },
  },
  {
    name: 'flux_marchand_conf',
    icon: '',
    options: {
      stats: false,
      filterInitiallyOn: true,
      dataPaginationdataPaginationMode: 'local',
      columnsDisplayed: 9,
      actions: { create: true, edit: true, delete: false },
      customInlineActions: [
        {
          name: 'monitoring-list-new-hebergement-type',
          label: '',
          classes: 'btn-primary btn-simple',
          title: 'Lancer le flux',
          icon: 'fa fa-play',
          canDisplay({ item }, context) {
            return item.active;
          },
          action: async ({
            item, action, location, props, id,
          }, context) => {
            if (await context.confirm('Lancer le flux ? ')) {
              context.$http.post(`/processus/start-flux/${item.id}`, {}).then(() => {
                context.$notify(`L\'import du flux [${item.nomMarchand}] à bien été lancé.`);
              });
            }
          },
        },
      ],
    },
  },
  {
    name: 'ville_association',
    icon: '',
    title: 'Association des villes',
    tableDataLimit: 100,
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      actions: { create: true, edit: true, delete: true },
    },
  },
  {
    name: 'marchand',
    icon: '',
    tableDataLimit: 100,
    options: {
      dataPaginationMode: 'remote',
    },
    actions: { create: true, edit: true, delete: false },
  },
  {
    name: 'produit',
    icon: '',
    options: {
      dataPaginationMode: 'remote',
      filterInitiallyOn: true,
      queryParams: {},
      actions: { create: false, edit: true, delete: true },
    },
    nestedModels: [
      {
        name: 'Traductions',
        identity: 'produit_lang',
        icon: '',
        title: false,
        options: {
          dataPaginationMode: 'local',
          modalMode: 'fade',
          url: ({ parent }) => `/crud/produit_lang?filters[id]=${parent.id}`,
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
          actions: {
            create: true,
            edit: true,
            delete: false,
            view: true,
            filter: false,
            dateFilter: false,
            search: false,
          },
        },
      },
      {
        name: 'Dispos',
        identity: 'disponibilite',
        icon: '',
        title: false,
        options: {
          dataPaginationMode: 'remote',
          modalMode: 'fade',
          url: ({ parent }) => `/crud/disponibilite?filters[idProduit]=${parent.id}`,
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
          actions: {
            create: false,
            edit: true,
            delete: false,
            view: true,
            filter: false,
            dateFilter: false,
            search: false,
          },
        },
      },

    ],
  },
  {
    name: 'residence',
    identity: 'residence',
    icon: '',
    tableDataLimit: 200,
    tableRowClickAction: 'none',
    tableRowDoubleClickAction: 'view',
    nestedLayoutMode: 'tab',
    actions: {
      create: true, edit: true, delete: true, view: true, dateFilter: false,
    },
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      detailPageMode: 'page',
      filterInitiallyOn: true,

      queryParams: {
        options: { searchMode: 'wildcard' },
      },
      customFormTopActions: [
        {
          name: 'associate-residence-virtuelle',
          label: '',
          class: 'btn btn-info mr-2 shadow',
          title: 'Associer à une autre résidence',
          icon: 'fa fa-link',
          canDisplay({ item }, context) {
            return item.flag && item.flag === 'virtuelle';
          },
          action: async ({
            item, action, location, props, id,
          }, context) => {
            const { value: targetID } = await Swal.fire({
              title: 'Entrer l\'id de la résidence',
              input: 'text',
              inputLabel: 'Residence id',
              inputValue: '',
              showCancelButton: true,
              inputValidator: (val) => {
                // eslint-disable-next-line no-restricted-globals
                if (!val || isNaN(val)) {
                  return 'Vous devez entrer l\'id de la résidence!';
                }
              },
            });
            if (targetID) {
              context.$http.post('/residence/association-virtuelle', {
                sourceID: item.id,
                targetID,
              })
                .then(async (result) => {
                  if (result && result.data && result.data.success) {
                    await context.$notify(`L'association à la résidence ${targetID} a été réalisé avec succès.`);
                    await context.$router.push('/app/residence').catch(() => { }).then(() => {
                      context.refreshComponent();
                    });
                  }
                })
                .catch((error) => {
                  context.$notify({
                    title: `Erreur lors de l'association de la résidence sur ${targetID}. ${error}`,
                    type: 'error',
                  });
                });
            }
          },
        },
        {
          name: 'import-residence-on-site',
          label: '',
          class: 'shadow btn-success',
          title: 'Envoyer en front',
          icon: 'fa fa-upload',
          type: 'dropdown',
          children: [
            {
              name: 'toocamp.com',
              title: 'Toocamp',
            },
            {
              name: 'lokapi.com',
              title: 'Lokapi',
            },
          ],
          action: async ({
            item, action, location, props, id, dropdownItem,
          }, context) => {
            if (await context.$awConfirm('Êtes-vous de vouloir effectuer cette action')) {
              context.$http.post('/residence/send-to-site', {
                residenceId: item.id,
                site: dropdownItem,
              })
                .then(async (response) => {
                  if (response && response.data) {
                    if (response.data.success) {
                      return context.$notify(`La résidence a été envoyé sur ${dropdownItem.name}.`);
                    }
                    if (response.data.callToAction) {
                      const { value } = await Swal.fire({
                        title: 'La ville n\'existe pas en front',
                        text: 'Voulez vous l\'envoyer depuis booster ?',
                        showConfirmButton: true,
                        showCancelButton: true,
                      });
                      if (value) {
                        return context.$router.push(`/app/ville/${item.idVille}`);
                      }
                    } else {
                      return context.$notify({
                        title: `Erreur lors de l'envoi de la résidence sur ${dropdownItem.name}.`,
                        footer: response.data.message || 'error_server',
                        type: 'error',
                      });
                    }
                  }
                  return context.$notify({
                    title: `Erreur lors de l'envoi de la résidence sur ${dropdownItem.name}.`,
                    type: 'error',
                  });
                })
                .catch((error) => context.$notify({
                  title: `Erreur lors de l'envoi de la résidence sur ${dropdownItem.name}.`,
                  footer: error.message || 'server_error',
                  type: 'error',
                }));
            }
          },
        },
      ],
      customInlineActions: [
        {
          name: 'residece-editorial',
          label: '',
          classes: 'btn-primary btn-simple',
          title: 'Editorial',
          icon: 'fa fa-edit',
          action: ({
            item, action, location, props, id,
          }, context) => {
            context.$router.push({
              path: `/app/residence/${item.id}/editorial`,
            });
          },
        },
      ],
    },
    customTableTopActions: [
      {
        type: 'filter',
        field: 'flag',
      },
      {
        type: 'filter',
        field: 'idPays',
      },
      {
        type: 'input',
        field: 'produits.disponibilites.id',
      },
      {
        type: 'input',
        field: 'produits.id',
      },
    ],

    nestedModels: [
      {
        name: 'Résidence proche',
        namePlural: 'Résidences proches',
        identity: 'residence-near',
        icon: '',
        title: 'Résidences proches',
        tableRowClickAction: 'none',
        initiallyCollapsed: true,
        actions: {
          filter: false,
          dateFilter: false,
          create: false,
          edit: false,
          delete: false,
          view: true,
          search: true,
        },
        options: {
          dataPaginationMode: 'remote',
          detailPageMode: 'modal',
          url: ({ parent }) => (parent && parent.lat ? `/residence?filters[nearLat][$custom]=${parent && parent.lat ? parent.lat : ''}&filters[nearLng][$custom]=${parent && parent.lng ? parent.lng : ''}&filters[id][$ne]=${parent.id}` : '/'),
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'wildcard' },
          },
          viewPath: '/app/residence/{{id}}',
          customBulkActions: [
            {
              name: 'associate-all-residence-virtuelle',
              label: 'Associer à la résidence sélectionnée',
              classes: 'btn btn-info mr-2',
              title: 'Associer à la résidence sélectionnée',
              icon: 'fa fa-link',
              action: async ({ items, parent }, context) => {
                if (await context.confirm(`Êtes-vous de vouloir associer ces résidences à la résidence ${parent && parent.id} ?`)) {
                  const promises = [];
                  items.forEach((item) => {
                    promises.push(
                      new Promise((resolve, reject) => {
                        context.$http.post('/residence/association-virtuelle', {
                          sourceID: item.id,
                          targetID: parent.id,
                        }).then((result) => resolve(result))
                          .catch((err) => reject(err));
                      }),
                    );
                  });
                  Promise.all(promises)
                    .then(async (result) => {
                      if (result) {
                        await context.$notify(`L'association à la résidence ${parent.id} a été réalisé avec succès.`);
                        context.refreshComponent();
                      }
                    })
                    .catch(async (err) => {
                      await context.$notify({
                        title: `Erreur lors de l'association de la résidence sur ${parent.id}. ${err}`,
                        type: 'error',
                      });
                      context.refreshComponent();
                    });
                }
              },
            },
          ],
        },

      },
      {
        name: 'Traduction',
        identity: 'residence_lang',
        icon: '',
        title: 'Traductions',
        apiUrl: ({ parent }) => (parent.id ? `/residence_lang?filters[id]=${parent.id}` : ''),
        options: {
          dataPaginationMode: 'local',
          detailPageMode: 'modal',
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
          bodyParams: ({ parent }) => ({ id: parent.id }),
        },
        actions: {
          filter: false,
          dateFilter: false,
          search: false,
          create: true,
          edit: true,
          delete: false,
          view: true,
        },
      },
      {
        name: 'Infos',
        identity: 'residences_infos',
        icon: '',
        title: false,
        nestedDisplayMode: 'view',
        awFormDisplayHeader: false,
        initiallyCollapsed: true,
        apiUrl: ({ parent }) => (parent && parent.id ? `/crud/residences_infos?filters[id]=${parent.id}` : ''),
        options: {
          dataPaginationMode: 'local',
          detailPageMode: 'page',
          modalMode: 'page',
          useRouterMode: true,
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
        },
        actions: {
          create: true, edit: true, delete: false, view: true,
        },
      },
      /*
  {
    name: 'Description',
    identity: 'residence_flux_description',
    icon: '',
    title: false,
    nestedDisplayMode: 'view',
    options: {
      dataPaginationMode: 'local',
      modalMode: 'fade',
      url: ({ parent }) => `/crud/residence_flux_description?filters[idResidence]=${parent.id}`,
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
      actions: {
        create: true, edit: true, delete: false, view: true,
      },
    },
  },
*/
      {
        name: 'Photos',
        identity: 'residence_picture',
        icon: '',
        title: 'Photos',
        nestedDisplayMode: 'table',
        initiallyCollapsed: true,
        initialDisplayMode: 'list',
        listOptions: {
          imageField: 'urlExt',
          titleField: '',
          subtitleField: 'id',
          descriptionField: 'type',
        },
        apiUrl: ({ parent }) => (parent.id
          ? `/crud/residence_picture?filters[idResidence]=${parent.id}` : ''),
        actions: {
          create: true, edit: true, delete: false, view: true,
        },
        options: {
          dataPaginationMode: 'local',
          modalMode: 'fade',
          useRouterMode: true,
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
        },
      },

      {
        name: 'Produits',
        identity: 'produit',
        icon: '',
        title: false,
        initiallyCollapsed: true,
        options: {
          dataPaginationMode: 'remote',
          detailPageMode: 'fade',
          useRouterMode: true,
          url: ({ parent }) => (parent.id ? `/crud/produit?filters[idResidence]=${parent.id}` : ''),
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },
        },
        actions: {
          create: false,
          edit: true,
          delete: false,
          view: true,
          filter: false,
          dateFilter: false,
          search: false,
        },
      },
      {
        name: 'Disponibilité',
        identity: 'disponibilite',
        title: 'disponibilités',
        namePlural: 'disponibilités',
        icon: '',
        initiallyCollapsed: true,
        apiUrl: ({ parent }) => (parent.id ? `/disponibilite?filters[residence.id]=${parent.id}` : ''),
        options: {
          dataPaginationMode: 'remote',
          detailPageMode: 'fade',
          useRouterMode: true,
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },

        },
        actions: {
          create: false,
          edit: false,
          delete: false,
          view: true,
          filter: false,
          dateFilter: false,
          search: false,
        },
      },
    ],
  },
  {
    name: 'disponibilité',
    route: 'disponibilite',
    title: 'disponibilités',
    identity: 'disponibilite',
    namePlural: 'disponibilités',
    icon: '',
    options: {
      dataPaginationMode: 'remote',
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
    },
    actions: { create: false, edit: true, delete: false },
    customTableTopActions: [
      {
        type: 'input',
        field: 'Produit.residence.id_ville',
      },
      {
        type: 'input',
        field: 'Produit.residence.type',
      },
    ],
    nestedModels: [
      {
        name: 'Mise en relation',
        identity: 'disponibilite_lang',
        icon: '',
        title: false,
        actions: {
          create: true,
          edit: true,
          delete: false,
          view: true,
        },
        apiUrl: ({ parent }) => (parent.id ? `/crud/disponibilite_lang?filters[id]=${parent.id}` : ''),
        options: {
          dataPaginationMode: 'local',
          modalMode: 'fade',
          filterInitiallyOn: true,
          queryParams: {
            options: { searchMode: 'exact' },
          },

        },
      },
    ],
  },
  {
    name: 'hebergement_type',
    icon: '',
    actions: { create: true, edit: true, delete: true },
    options: { dataPaginationMode: 'local' },
  },
  {
    name: 'correspondance',
    icon: 'fa fa-exchange-alt',
    options: { dataPaginationMode: 'remote' },
    actions: { create: true, edit: true, delete: true },
  },
  {
    name: 'statistiques',
    icon: '',
    options: {
      stats: false,
      filterInitiallyOn: true,
      columnsDisplayed: 14,
      dataPaginationMode: 'remote',
    },
    actions: { create: false, edit: false, delete: false },
  },
  {
    name: 'monitoring',
    icon: '',
    segmentField: 'statut',
    actions: {
      view: true, create: false, edit: true, delete: true,
    },
    options: {
      dataPaginationMode: 'remote',
      filterInitiallyOn: true,
      detailPageMode: 'page',
      autoRefresh: true,
      autoRefreshInterval: 10,
      customTopRightActions: [
        {
          name: 'clear-monitoring-list',
          label: 'Effacer',
          classes: 'btn-danger',
          title: 'Effacer tous les logs',
          icon: 'fa fa-trash',
          action: async ({ item }, context) => {
            if (await context.confirm('Êtes-vous de vouloir effectuer cette action')) {
              context.$http.delete('/monitoring/clear').then(() => {
                context.refreshComponent();
              });
            }
          },
        },
      ],
    },

    listOptions: {
      titleField: 'command',
      subtitleField: 'last_modified_on',
      descriptionField: 'status',
      labelField: 'status',
    },
  },

  {
    name: 'app_monitoring',
    identity: 'app_monitoring',
    route: 'app_monitoring',
    actions: { create: true, edit: true, delete: true },
    options: {
      dataPaginationMode: 'local',
      autoRefresh: true,
      columnsDisplayed: 12,
    },
  },
  {
    name: 'site',
    identity: 'site',
    route: 'site',
    actions: { create: true, edit: true, delete: true },
    options: {
      dataPaginationMode: 'local',
      columnsDisplayed: 12,
    },
  },
  {
    name: 'mapping_site_caracteristique',
    identity: 'mapping_site_caracteristique',
    route: 'mapping_site_caracteristique',
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
    options: {
      dataPaginationMode: 'remote',
      columnsDisplayed: 12,

    },
  },
  {
    name: 'mapping_site_produit',
    identity: 'mapping_site_produit',
    route: 'mapping_site_produit',
    options: {
      dataPaginationMode: 'remote',
      columnsDisplayed: 12,
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
  {
    name: 'mapping_site_residence',
    identity: 'mapping_site_residence',
    route: 'mapping_site_residence',
    options: {
      dataPaginationMode: 'remote',
      columnsDisplayed: 12,
    },
  },
  {
    name: 'mapping_site_ville',
    identity: 'mapping_site_ville',
    route: 'mapping_site_ville',
    options: {
      dataPaginationMode: 'remote',
      columnsDisplayed: 12,
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
  {
    name: 'site_marchand',
    identity: 'site_marchand',
    route: 'site_marchand',
    options: {
      dataPaginationMode: 'local',
      columnsDisplayed: 12,
    },
    actions: { create: true, edit: true, delete: true },
  },
  {
    name: 'mapping_site_residence_type',
    identity: 'mapping_site_residence_type',
    route: 'mapping_site_residence_type',
    options: {
      dataPaginationMode: 'local',
      columnsDisplayed: 12,
    },
    actions: { create: true, edit: true, delete: true },

  },
  {
    name: 'residence_type',
    identity: 'residence_type',
    route: 'residence_type',
    options: {
      dataPaginationMode: 'local',
      columnsDisplayed: 12,
    },
    actions: { create: true, edit: true, delete: true },
  },
  {
    name: 'ville',
    identity: 'ville',
    route: 'ville',
    actions: { create: true, edit: true, delete: true },
    options: {
      dataPaginationMode: 'remote',
      columnsDisplayed: 12,
    },
    customFormTopActions: [
      {
        name: 'import-ville-on-site',
        label: '',
        classes: 'shadow btn-success',
        title: 'Envoyer en front',
        icon: 'fa fa-upload',
        type: 'dropdown',
        dropdownDirection: 'bottom',
        children: [
          {
            name: 'toocamp.com',
            title: 'Toocamp',
          },
          {
            name: 'lokapi.com',
            title: 'Lokapi',
          },
        ],
        action: async (data, context) => {
          const { item, dropdownItem } = data;
          await sendToSite(item, dropdownItem, context, '/crud/ville');
        },
      },
    ],
  },
  {
    name: 'region_admin',
    identity: 'region_admin',
    route: 'region_admin',
    options: {
      dataPaginationMode: 'remote',
      autoRefresh: true,
      columnsDisplayed: 12,
    },
    actions: { create: true, edit: true, delete: true },
    customFormTopActions: [
      {
        name: 'import-region-on-site',
        label: '',
        classes: 'shadow btn-success',
        title: 'Envoyer en front',
        icon: 'fa fa-upload',
        type: 'dropdown',
        children: [
          {
            name: 'toocamp.com',
            title: 'Toocamp',
          },
          {
            name: 'lokapi.com',
            title: 'Lokapi',
          },
        ],
        action: async ({ item, dropdownItem }, context) => {
          await sendToSite(item, dropdownItem, context, '/crud/region_admin');
        },
      },
    ],
  },
  {
    name: 'departement',
    identity: 'departement',
    route: 'departement',
    options: {
      dataPaginationMode: 'remote',
      autoRefresh: true,
      columnsDisplayed: 12,
    },
    actions: { create: process.env.VUE_APP_MODE === 'VISION', edit: true, delete: true },
  },
  {
    name: 'pays',
    identity: 'pays',
    route: 'pays',
    options: {
      dataPaginationMode: 'local',
      autoRefresh: true,
      columnsDisplayed: 12,
    },
    actions: { create: true, edit: true, delete: true },
    customFormTopActions: [
      {
        name: 'import-pays-on-site',
        label: '',
        classes: 'shadow btn-success',
        title: 'Envoyer en front',
        icon: 'fa fa-upload',
        type: 'dropdown',
        children: [
          {
            name: 'toocamp.com',
            title: 'Toocamp',
          },
          {
            name: 'lokapi.com',
            title: 'Lokapi',
          },
        ],
        action: async ({ item, dropdownItem }, context) => {
          await sendToSite(item, dropdownItem, context, '/crud/pays');
        },
      },
    ],
  },

  {
    name: 'user',
    icon: '',
    actions: { create: true, edit: true, delete: true },
    options: {
      dataPaginationMode: 'remote',
      customActions: [
        /*
        {
          name: 'list-user-ride',
          label: '',
          classes: 'btn-warning',
          title: 'Voir les courses',
          icon: 'fa fa-car',
          action: function(item, context) {
            context.$router.push('/app/ride?filters[userId]=' + item._id);
          },
        },
        {
          name: 'list-user-promocodes',
          label: '',
          classes: 'btn-warning',
          title: 'Voir les promo code opérations',
          icon: 'fa fa-bookmark',
          action: function(item, context) {
            context.$router.push('/app/promocode_operation?filters[userId]=' + item._id);
          },
        },
        */
      ],
    },

    nestedModels: [],
  },
  {
    name: 'administrator',
    identity: 'user',
    route: 'administrator',
    options: {
      queryParams: { roles: 'ADMIN' },
      dataPaginationMode: 'local',
    },
    actions: { create: true, edit: true, delete: true },
  },
  {
    name: 'caracteristique',
    identity: 'caracteristique',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      detailPageMode: 'sideform',
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
  {
    name: 'pension',
    identity: 'pension',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      detailPageMode: 'fade',
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
  {
    name: 'option_type',
    identity: 'option_type',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      detailPageMode: 'fade',
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
      actions: {
        create: true, edit: true, delete: true, view: true,
      },
    },
  },
  {
    name: 'option',
    identity: 'option',
    icon: '',
    options: {
      stats: false,
      dataPaginationMode: 'remote',
      detailPageMode: 'fade',
      filterInitiallyOn: true,
      queryParams: {
        options: { searchMode: 'exact' },
      },
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
  {
    name: 'association_pensions',
    identity: 'association_pensions',
    route: 'association_pensions',
    options: {
      dataPaginationMode: 'remote',
      autoRefresh: true,
      columnsDisplayed: 12,
    },
    actions: {
      create: true, edit: true, delete: true, view: true,
    },
  },
];
