<template>
  <div class="content" id="overviewPage">
    <div class="container-fluid">
      <form @submit.prevent="search()">
        <div class="row m-0">
          <div class="col-sm-10 col-xs-12 text-left p-1">
            <VueSelect
              class="bg-white form-vue-select"
              placeholder="Entrez un id marchand"
              v-model="idMarchands"
              :options="marchandsList"
              label="label"
              :reduce="(item) => item.id"
              :multi="true"
              :multiple="true"
              @input="search"
            />
          </div>
          <div class="col-sm-1 col-xs-12 text-left p-1">
            <button @click="search()" class="btn btn-icon btn-sm btn-success btn-block">
              <i class="fa fa-search"></i>
            </button>
          </div>
           <div class="col-sm-1 col-xs-12 text-left p-1">
            <button @click="clear()" class="btn btn-icon btn-sm btn-success btn-block">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </form>
      <hr />

      <div class="row" v-if="idMarchands.length">
        <div class="col-xs-12 col-md-12 text-right">

          <div class="btn-group">
            <button  class="btn btn-primary" @click="startImport()">
              <i class="fa fa-play"></i> Lancer le sourcing
            </button>
            <div class="dropdown">
              <button
                class="ml-1 btn btn-success dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                arias-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-upload"></i>
                Importer
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  v-for="site in sites"
                  :key="site.id"
                  @click="sendToSingleSite({ id_marchand: idMarchands, ...site })"
                  class="dropdown-item"
                  >{{ site.nom }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { rolesMixin } from 'vue-aw-components';
import VueSelect from 'vue-select';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
// import qs from 'qs';

export default {
  name: 'Debugger',
  mixins: [rolesMixin],
  components: { VueSelect },
  data() {
    return {

      idMarchands: [],
      sites: [],
    };
  },
  mounted() {
    this.getSites();
    this.searchDebounced = _.debounce(this.search);
    try {
      if (!this.$store.state.marchands || !this.$store.state.marchands.length) {
        this.$store.dispatch('getMarchands');
      }
      const idMarchand = this.$route.params.idMarchand || this.$route.query.idMarchand;
      if (idMarchand) {
        this.idMarchand = idMarchand;
        this.search();
        return;
      }
      const id = sessionStorage.getItem('flux_debugger_current_marchand');
      if (id) {
        this.idMarchands = JSON.parse(id);
        // this.$router.push(`/app/debugger/${this.idMarchand}`);
      }
      //  this.$nextTick(() => this.$refs.marchand.click() && this.$refs.marchand.$el.click());
    } catch (e) {
      console.warn(e);
    }
  },

  beforeDestroy() {},

  computed: {
    marchandsList() {
      return this.$store.state.marchands && this.$store.state.marchands
        ? this.$store.state.marchands.map((m) => ({ id: m.id, label: `${m.nom} (${m.id})` })).sort((a, b) => a.id - b.id)
        : [];
    },
  },
  methods: {
    search() {
      sessionStorage.setItem('flux_debugger_current_marchand', JSON.stringify(this.idMarchands));
    },

    clear() {
      this.idMarchands = [];
      sessionStorage.setItem('flux_debugger_current_marchand', []);
    },

    formatDate(date) {
      if (!date) {
        return;
      }
      return moment(date).format('dddd - DD MMM');
    },

    formatNumber(number) {
      if (!number) {
        return number;
      }
      return number.toLocaleString('de-DE', { minimumFractionDigits: 0 });
    },

    getSites() {
      this.marchandConfs = null;
      this.$http
        .get(
          '/crud/site',
        )
        .then((res) => {
          this.sites = res.data.body;
        })
        .catch((err) => console.warn(err));
    },

    titleCase(str) {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
    },

    actionCallback(body) {
      const { item } = body;
      this.sendToToocamp(item);
    },

    sendToSingleSite(site) {
      // eslint-disable-next-line
      if (window.confirm('Êtes-vous de vouloir effectuer cette action ?')) {
        this.idMarchands.forEach(id => {
          this.$http
            .post(`/marchand/${id}/send-to-toocamp`, {}, { params: { id_site: site.id } })
            .then(() => {
              this.$notify(`Marchand envoyé sur ${site.nom}`);
            })
            .catch((err) => this.$notify({
              title: `Le stock n'a pas pu être envoyé vers ${site.nom}: ${err}`,
              type: 'danger',
            }));
        });
      }
    },

    startImport() {
      // eslint-disable-next-line
      if (window.confirm(`Lancer les flux des marchands ${this.idMarchands} ? `)) {
        this.idMarchands.forEach(id => {
          this.$http
            .post(`/processus/start-flux?idMarchand=${id}`, {})
            .then(() => this.$notify('Le sourcing du marchand à bien été lancé.'))
            .catch((err) => this.$notify({
              title: `Le sourcing n'a pas pu être lancé: ${err}`,
              type: 'danger',
            }));
        });
      }
    },

    numberFormat(data) {
      return new Intl.NumberFormat('de-DE').format(data);
    },
  },
};
</script>
<style lang="scss">
.contracts-table {
  .card-body {
    height: 343px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.form-vue-select {
  height: 100%;
  .vs__dropdown-toggle {
    height: 100%;
  }
}
</style>
