import qs from 'qs';
import { uniq, startCase, cloneDeep } from 'lodash';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Vue from 'vue';
import config from '@/config';
import localeFr from '@/config/locales/fr.json';
import localeEn from '@/config/locales/en.json';
// import marchands from './marchands';

Vue.use(Vuex);

const token = localStorage.getItem(`${config.appName}_token`);
let models;
let user;
try {
  user = localStorage.getItem(`${config.appName}_user`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_user`))
    : '';

  models = localStorage.getItem(`${config.appName}_models`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_models`))
    : [];
} catch (err) {
  console.error(err);
}

function getExtendedRoles(role, state) {
  let myRoles = [];
  if (config.roles && config.roles[role] && config.roles[role].inherits && Array.isArray(config.roles[role].inherits)) {
    myRoles = myRoles.concat(config.roles[role].inherits);
    config.roles[role].inherits.forEach((r) => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return uniq(myRoles);
}

const automaticModelsState = config.automaticModels.reduce((state, model) => {
  state[model.name || model.identity] = 1;
  return state;
}, {});

const vuexLocal = new VuexPersistence({
  key: `vuex_${config.appKey}`,
  storage: window.localStorage,
});

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {},
  state: {
    config,
    user,
    token,
    io: null,
    translations: { fr: localeFr, en: localeEn },
    locale: user.locale || localStorage.getItem(`${config.appName}_locale`) || config.defaultLocale,
    userExtendedRoles: [],
    data: {
      resources: [],
      tasks: [],
      models,
      automaticModelsState,
    },
    trelloCardCount: localStorage.getItem(`${config.appName}_trelloCardCount`) || '',
    marchands: [],
  },
  mutations: {
    marchands(state, data) {
      state.marchands = data;
    },

    resources(state, resources) {
      state.data.resources = resources;
    },

    tasks(state, tasks) {
      state.data.tasks = tasks;
    },

    extendedRoles(state) {
      let myRoles = state.user.roles || [];
      myRoles.forEach((role) => {
        myRoles = myRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = myRoles;
    },

    io(state, io) {
      state.io = io;
    },

    models(state, appModels) {
      state.data.models = appModels;
      localStorage.setItem(`${config.appName}_models`, JSON.stringify(appModels));
    },

    user(state, currentUser) {
      state.user = currentUser;
      state.locale = currentUser.locale;
      localStorage.setItem(`${config.appName}_user`, JSON.stringify(currentUser));
    },

    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appName}_locale`, locale);
    },

    token(state, appToken) {
      this._vm.$http.defaults.headers.common.Authorization = `Bearer ${appToken}`;
      this._vm.$http.defaults.headers.Authorization = `Bearer ${appToken}`;
      state.token = appToken;
      localStorage.setItem(`${config.appName}_token`, appToken);
    },

    commitData(state, key, value) {
      state.data[key] = value;
    },

    trelloCardCount(state, value) {
      state.trelloCardCount = value;
      localStorage.setItem(`${config.appName}_trelloCardCount`, value);
    },
  },
  getters: {
    automaticModels(state) {
      return state.data.models
        ? Object.keys(automaticModelsState).map((key) => state.data.models.find((model) => model.identity === key))
        : [];
    },
  },
  actions: {
    logout({ commit }) {
      delete App.user;
      commit('user', '');
      commit('token', '');
      commit('models', []);
      return true;
    },

    user({ commit }, currentUser) {
      commit('user', currentUser);
      commit('extendedRoles');
      return true;
    },

    refreshUser({ commit, dispatch }) {
      const q = this._vm.$http.get('/auth/user?isBackOfficeMode=true');
      q.then((res) => {
        App.user = res.data.user;
        localStorage.setItem(`${config.appName}_user`, JSON.stringify(res.data.user));
        dispatch('user', res.data.user);
      }).catch((err) => {
        console.warn('refresh error', err);
        this._vm.$notify({
          title: err.response ? err.response.data : err,
          type: 'warning',
        });
        if (err.response && err.response.status === 404) {
          dispatch('logout');
        }
      });
      return q;
    },

    getModels({ commit }) {
      const promise = this._vm.$http.get('/models');
      promise
        .then((res) => {
          const apiModels = res.data.body.map((model) => {
            model.title = model.title || startCase(model.identity || model.name);
            model.modelName = model.modelName || model.identity || model.name;
            return model;
          });
          const nearResidenceModel = cloneDeep(apiModels.find((m) => m.identity === 'residence'));
          nearResidenceModel.identity = 'residence-near';
          const tempProperties = {};
          Object.keys(nearResidenceModel.schema.properties).forEach((field) => {
            if (['id', 'nom', 'idVille', 'idPays', 'lat', 'lng', 'distance', 'flag'].includes(field)) {
              tempProperties[field] = nearResidenceModel.schema.properties[field];
            }
          });
          nearResidenceModel.schema.properties = tempProperties;
          apiModels.push(nearResidenceModel);

          const editorialResidenceModel = cloneDeep(apiModels.find((m) => m.identity === 'residence'));
          editorialResidenceModel.identity = 'residence-editorial';
          const tempProperties2 = {};
          Object.keys(editorialResidenceModel.schema.properties).forEach((field) => {
            if (
              [
                'id',
                'nom',
                'idVille',
                'idPays',
                'idDepartement',
                'dateCreation',
                'dateModification',
                'idRegionAdmin',
                'type',
                'caracteristiques',
                'lat',
                'lng',
                'flag',
                'commentaire',
              ].includes(field)
            ) {
              tempProperties2[field] = editorialResidenceModel.schema.properties[field];
            }
          });
          editorialResidenceModel.schema.properties = tempProperties2;
          apiModels.push(editorialResidenceModel);


          const editorialResidencPictureModel = cloneDeep(apiModels.find((m) => m.identity === 'residence_picture'));
          editorialResidencPictureModel.identity = 'residence-picture';
          const tempProperties3 = {};
          Object.keys(editorialResidencPictureModel.schema.properties).forEach((field) => {
            if (
              [
                'id',
                'url',
                'idResidence',
                'src',
                'type',
                'saison'
              ].includes(field)
            ) {
              tempProperties3[field] = editorialResidencPictureModel.schema.properties[field];
            }
          });
          editorialResidencPictureModel.schema.properties = tempProperties3;
          apiModels.push(editorialResidencPictureModel);

          commit('models', apiModels);
        })
        .catch((err) => {
          console.error(err);
        });
      return promise;
    },

    getTasks({ commit, state }, query) {
      query = query || {};
      this._vm.$http
        .get('/crud/project_tasks', {
          params: query,
          paramsSerializer(params) {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
            });
          },
        })
        .then((res) => {
          const tasks = res.data.body.map((task) => {
            const project = state.data.projects && state.data.projects.find((p) => task.projectId === p._id);
            task.project_name = project ? project.code || project.name : 'NOT FOUND';
            return task;
          });

          commit('tasks', tasks);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getTranslations(context) {
      const p = this._vm.$http.get(`/api/translations/${context.state.locale}`);

      p.then((res) => {
        context.commit('translations', res.data);
      }).catch((err) => {
        console.warn(err);
      });

      return p;
    },

    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
      // context.dispatch('getTranslations');
    },
    // eslint-disable-next-line
    init(context) {
      //  return context.dispatch('getTranslations');
    },

    getMarchands({ commit }) {
      const promise = this._vm.$http.get('/marchand?perPage=10000');
      promise
        .then((res) => {
          const data = res.data.body.map((item) => {
            item.nom = item.nom || '';
            return item;
          });
          commit('marchands', data);
        })
        .catch((err) => {
          console.error(err);
        });
      return promise;
    },
  },
});

export default store;
